import React from 'react'
import { Link } from 'react-router-dom'
import { useStore } from '../../store/AppContext';
import TooltipWrapper from '../TooltipWrapper';

export const NavigationLink = ({ link, title, icon, lightModeIcon, setSection, sectionTitle, selectedIcon, disabled, toolTip, toolTipPosition }) => {
    const { state } = useStore();
    const isSelected = sectionTitle === title;

    return (
        <Link to={!disabled && link} onClick={() => !disabled && setSection(title)} className={`flex p-4 mb-2 font-medium items-center justify-between hover:bg-white-gray-700 hover:dark:bg-gray-medium rounded-lg transition-colors duration-200 ${disabled && 'cursor-default'} ${isSelected ? 'bg-white-gray-500 dark:bg-gray-black text-black dark:text-white' : 'text-gray dark:text-white'}`}>
            <div className='flex items-center'>
                <img src={isSelected ? selectedIcon : (state.isDarkMode ? icon : lightModeIcon)} className='w-6 mr-2 left-0' alt="" />
                <p className={`ml-2 ${disabled && 'text-gray-light dark:text-gray-light cursor-default'}`}>
                    {title}
                </p>
            </div>
            {toolTip &&
                <TooltipWrapper content={toolTip} tootTipStyle={`w-[220px] absolute -ml-40 ${toolTipPosition === 'top' ? '-mt-24' : '-mt-16'} z-50`} arrowClassName={`${toolTipPosition === 'top' ? '-ml-[1px]' : 'ml-[2px]'}`} autoHide={false} isShowArrow={true} onTop={toolTipPosition === 'top'}>
                    <div className='flex ml-1 items-center justify-center'>
                        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 2C6.65625 2 4.53125 3.25 3.34375 5.25C2.1875 7.28125 2.1875 9.75 3.34375 11.75C4.53125 13.7812 6.65625 15 9 15C11.3125 15 13.4375 13.7812 14.625 11.75C15.7812 9.75 15.7812 7.28125 14.625 5.25C13.4375 3.25 11.3125 2 9 2ZM9 16.5C6.125 16.5 3.5 15 2.0625 12.5C0.625 10.0312 0.625 7 2.0625 4.5C3.5 2.03125 6.125 0.5 9 0.5C11.8438 0.5 14.4688 2.03125 15.9062 4.5C17.3438 7 17.3438 10.0312 15.9062 12.5C14.4688 15 11.8438 16.5 9 16.5ZM7.75 11H8.5V9H7.75C7.3125 9 7 8.6875 7 8.25C7 7.84375 7.3125 7.5 7.75 7.5H9.25C9.65625 7.5 10 7.84375 10 8.25V11H10.25C10.6562 11 11 11.3438 11 11.75C11 12.1875 10.6562 12.5 10.25 12.5H7.75C7.3125 12.5 7 12.1875 7 11.75C7 11.3438 7.3125 11 7.75 11ZM9 6.5C8.4375 6.5 8 6.0625 8 5.5C8 4.96875 8.4375 4.5 9 4.5C9.53125 4.5 10 4.96875 10 5.5C10 6.0625 9.53125 6.5 9 6.5Z" fill="#788493" />
                        </svg>
                    </div>
                </TooltipWrapper>
            }
        </Link>
    )
}

export const NavigationButton = ({ handleClick, title, icon }) => {
    return (
        <div onClick={handleClick} className="text-gray dark:text-white font-medium flex p-4 mb-2 cursor-pointer hover:bg-white-gray-700 hover:dark:bg-gray-medium active:bg-gray-black  rounded-lg transition-colors duration-200">
            <img src={icon} className='w-6 mx-2' alt="" />
            <span className='ml-2'>
                {title}
            </span>
        </div>
    )
}
