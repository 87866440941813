import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faShareFromSquare } from '@fortawesome/free-regular-svg-icons';
import { EXPORT_TYPES } from '../config';
import jsPDF from 'jspdf';

const ShareModel = ({ chatID, prevChat }) => {
    const shareButtonRef = useRef(null);
    const optionsRef = useRef(null);
    const [optionList, setOptionList] = useState(false);
    const [clickedPosition, setClickedPosition] = useState(false);
    
    const handleClickOutside = (event) => {
        setClickedPosition(event.clientY);
        if (shareButtonRef.current && !shareButtonRef.current.contains(event.target) && optionsRef.current && !optionsRef.current.contains(event.target)) {
            setOptionList(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClick = () => {
        setOptionList(!optionList);
    };

    const onShare = (exportType) => {
        if (exportType === EXPORT_TYPES.PDF)
            generatePDF();
        
        setOptionList(false);
    };

    const generatePDF = async () => {
        const elementToPrintId = chatID + "_bot"
        const element = document.getElementById(elementToPrintId);
        if (!element) {
            throw new Error(`Element with id ${elementToPrintId} not found`);
        }

        const textContent = element.innerText || element.textContent;
        const pdf = new jsPDF({ orientation: "portrait", unit: "mm", format: "a4", });
        const lines = pdf.splitTextToSize(textContent, 270);
        const fileName = (prevChat?.user?.mssg || "Your_answer") + ".pdf"

        pdf.setFont("times", "normal")
        pdf.setFontSize(12);
        pdf.text(lines, 10, 6);
        pdf.save(fileName);
    };

    return (
        <div>
            <button ref={shareButtonRef} onClick={handleClick} className={`px-2 w-8 h-8 py-1 rounded-md hover:bg-white-gray-500 hover:dark:bg-gray-300`}>
                <FontAwesomeIcon icon={faShareFromSquare} className={`text-gray-dark dark:text-white w-4 h-4 font-thin`} />
            </button>
            {optionList && (
                <div ref={optionsRef} className={`absolute ${(clickedPosition < window.innerHeight * 0.65) ? '' : '-mt-20'} flex flex-row z-50 left-auto text-gray-dark-500 dark:text-white bg-white-gray-600 dark:bg-gray-black px-2 text-xs py-2 rounded-lg`}>
                    <button onClick={() => onShare(EXPORT_TYPES.PDF)} className={`flex items-center px-2 h-8 py-1 rounded-md hover:bg-white-gray-500 hover:dark:bg-gray-300`}>
                        <FontAwesomeIcon icon={faFilePdf} className={`text-gray-dark dark:text-white w-4 h-4 font-thin`} />
                        <span className="ml-2 text-xs text-gray-dark dark:text-white">Export as PDF</span>
                    </button>
                </div>
            )}
        </div>
    );
};

export default ShareModel;
