import React, { useEffect, useState } from 'react';
import { InputWrapper } from "../../components/InputWrapper";
import { CopyBlock, hybrid } from 'react-code-blocks';
import { useDbSearch } from '../../hooks/useDbSearch';
import { useStore } from '../../store/AppContext';
import {BASE_URL} from "../../config";
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SidePanelDBSearch } from '../../components/DBSearch/SidePanel';

const DbSearchResult = () => {
    const dbSearchHook = useDbSearch();
    const { state, dispatch } = useStore();
    const [query, setQuery] = useState(null);
    const [response, setResponse] = useState(null);
    const [samplequery, setSampleQuery] = useState([])
    const [selectedDatabase, setSelectedDatabase] = useState([])
    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        dbSearchHook.connectDatabase(false, { database_type: "", username: state.user.username, integration:'local' })
    }, [])

    useEffect(() => {
        if(state.dbSearchSampleQuery)
            setSampleQuery(state.dbSearchSampleQuery);
    }, [state.dbSearchSampleQuery])

    useEffect(() => {
        setResponse(state.dbSearchResult);
        dispatch({
            type: "SET_DB_SEARCH_STREAMING",
            payload: false
        })
    }, [state.dbSearchResult])

    useEffect(() => {
        setResponse(null);
    }, [query])

    const searchQuery = () => {
        if(selectedDatabase.length > 0) {
            dispatch({
                type: "SET_DB_SEARCH_STREAMING",
                payload: true
            })
            const selectedDatabaseNameList = selectedDatabase.map(table => table.database_name);
            const request = {
                db_names: selectedDatabaseNameList,
                user_query: query,
            }
            dbSearchHook.getDbSearchResponse(request);
        }
        else {
            dispatch({
                type: "SET_MESSAGE",
                payload: {
                    type: "fail",
                    title: "Failed to connect database",
                    subtitle: "Please select the database"
                }
            })
        }
    }

    const onEnterKey = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            !state.isDBSearchStreaming && query && searchQuery();
        }
    }

    const onSampleQuestionSelect = (value) =>{
        if(selectedDatabase.length > 0) {
            if(!state.isDBSearchStreaming){
                setQuery(value)
                dispatch({
                    type: "SET_DB_SEARCH_STREAMING",
                    payload: true
                })
            
                const selectedDatabaseNameList = selectedDatabase.map(table => table.database_name);
                const request = {
                    db_names: selectedDatabaseNameList,
                    user_query: value,
                }
            
                dbSearchHook.getDbSearchResponse(request);
            }
        }
        else {
            dispatch({
                type: "SET_MESSAGE",
                payload: {
                    type: "fail",
                    title: "Empty database list",
                    subtitle: "Please select the database"
                }
            })
        }
    }

    const handleNewChat = () =>{
        setResponse(null);
        setQuery(null)
    }

    return (
        <div className="flex relative chat-container ">
            <SidePanelDBSearch isOpen={isOpen} setIsOpen={setIsOpen} handleNewChat={handleNewChat} setSelectedDatabase={setSelectedDatabase}/>
            <div className={`${isOpen ? 'w-0' : 'w-16'} flex items-start mt-4`}>
                <button className={`text-gray-dark dark:text-white mx-auto ${isOpen && 'hidden'}`} onClick={() => setIsOpen(!isOpen)}>
                    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 2H7.875V15.5H15.75C16.3477 15.5 16.875 15.0078 16.875 14.375V3.125C16.875 2.52734 16.3477 2 15.75 2ZM2.25 2C1.61719 2 1.125 2.52734 1.125 3.125V14.375C1.125 15.0078 1.61719 15.5 2.25 15.5H6.75V2H2.25ZM2.25 0.875H15.75C16.9805 0.875 18 1.89453 18 3.125V14.375C18 15.6406 16.9805 16.625 15.75 16.625H2.25C0.984375 16.625 0 15.6406 0 14.375V3.125C0 1.89453 0.984375 0.875 2.25 0.875ZM2.8125 3.125H5.0625C5.34375 3.125 5.625 3.40625 5.625 3.6875C5.625 4.00391 5.34375 4.25 5.0625 4.25H2.8125C2.49609 4.25 2.25 4.00391 2.25 3.6875C2.25 3.40625 2.49609 3.125 2.8125 3.125ZM2.25 5.9375C2.25 5.65625 2.49609 5.375 2.8125 5.375H5.0625C5.34375 5.375 5.625 5.65625 5.625 5.9375C5.625 6.25391 5.34375 6.5 5.0625 6.5H2.8125C2.49609 6.5 2.25 6.25391 2.25 5.9375ZM2.8125 7.625H5.0625C5.34375 7.625 5.625 7.90625 5.625 8.1875C5.625 8.50391 5.34375 8.75 5.0625 8.75H2.8125C2.49609 8.75 2.25 8.50391 2.25 8.1875C2.25 7.90625 2.49609 7.625 2.8125 7.625Z" fill="currentColor" />
                    </svg>
                </button>
            </div>
            <main className="w-full mx-auto px-6">
                <div className='text-gray-dark-200 dark:text-gray-800 text-sm mb-6'>
                    <p>Connect your database and utilize natural language to query and retrieve data.</p>
                </div>
                <InputWrapper
                    value={query ? query : ''}
                    setValue={setQuery}
                    name={"query"}
                    type={"text"}
                    onEnterKey={onEnterKey}
                    placeholder='Enter your query here...'
                    rightIcon={
                        <button onClick={searchQuery} disabled={!query || state.isDBSearchStreaming}>
                            <img src={query && !state.isDBSearchStreaming ? "/assets/images/chevron-down-blue.svg" : "/assets/images/chevron-down.svg"} alt="" />
                        </button>
                    }
                />
                {response === null && samplequery && (
                    <div className="flex flex-wrap mt-4 justify-center text-gray-dark dark:text-white overflow-y-auto max-h-96 w-[95%]">
                        {samplequery.map((item) => (
                            <div
                                key={item.key}
                                className={`h-32 m-4 w-[40%] p-4 cursor-default ${state.isDBSearchStreaming && query === item.value ? 'text-gray-dark dark:text-white bg-white-gray-700 dark:bg-gray-black' : ''} ${!state.isDBSearchStreaming ? 'hover:bg-white-gray-700 hover:dark:bg-gray-black' : ''} rounded-lg border border-white-gray-400 dark:border-gray-400 relative`}
                            >
                                <div className="flex w-full h-20 p-1 text-gray-dark dark:text-white overflow-hidden">
                                    <div className="flex-1 overflow-hidden overflow-y-auto">{item.value}</div>
                                </div>
                                <div className={`absolute bottom-0 right-2 text-gray-dark dark:text-white ${state.isDBSearchStreaming ? 'cursor-default' : 'cursor-pointer'}`}>
                                    <FontAwesomeIcon className="w-5 p-2" icon={faArrowRight} disabled={state.isDBSearchStreaming} onClick={() => onSampleQuestionSelect(item.value)}/>
                                </div>
                            </div>                        
                        ))}
                    </div>
                )}
                {response &&
                    <div className="w-100 bg-white-gray-700 dark:bg-gray-300 mt-4 rounded-tl-md rounded-tr-md">
                        <div className='w-100 py-1'>
                            <p className='text-gray-dark dark:text-white text-base font-bold pl-2 pr-2 p-1 text-justify'>Results</p>
                        </div>

                        <div className="db-search-result-content-container overflow-auto hide-scrollbar">
                            <div className="bg-white-gray-500 dark:bg-gray-100">
                                {response.text && response.text !== '' &&
                                    <div className='text-gray-dark dark:text-white w-100 p-2'>
                                        <div dangerouslySetInnerHTML={{ __html: response.text.replace(/\n/g, "<br />") }} />
                                    </div>
                                }
                                {response.code && response.text !== '' &&
                                    <div className='w-100 text-gray-dark dark:text-white'>
                                        <CopyBlock
                                            text={response.code}
                                            language='sql'
                                            showLineNumbers={false}
                                            theme={hybrid}
                                            wrapLines={true}
                                            codeBlock
                                        />
                                    </div>
                                }
                                {response.image && response.text !== '' &&
                                    <div className='flex flex-col text-gray-dark dark:text-white items-center w-100 py-2'>
                                        <img className='w-10/12' src={`${BASE_URL}${response.image.replace('/interplay_v2/public/private','')}?id=${new Date()}`} />
                                    </div>
                                }
                                {response.list && response.text !== '' &&
                                    <div className='text-gray-dark dark:text-white w-100 p-2'>
                                        <ol style={{ display: 'list-item' }}>
                                            {response.list.length > 0 && response.list.map((item, key) => {
                                                return (
                                                    <li key={key + 1} style={{ display: 'list-item', listStylePosition: 'inside' }}>{`${item}`}</li>
                                                )
                                            })}
                                        </ol>
                                    </div>
                                }
                                {response.table &&
                                    <div className="flex flex-row overflow-y-scroll justify-center w-full">
                                        <table className='w-100 text-gray-dark dark:text-white bg-white-gray dark:bg-gray-light-200 scroll-auto'>
                                            <tr className='w-100 border border-gray-600'>
                                                {response.table.header && response.table.header.map((title, index) => {
                                                    return (
                                                        <th className='px-4 py-1' key={index}>{title}</th>
                                                    )
                                                })}
                                            </tr>
                                            {response.table.rows && response.table.rows.map((dataRow,index) => {
                                                return (
                                                    <tr key={index} className='text-center border border-gray-600'>
                                                        {dataRow.map((value, i) => {
                                                            return (
                                                                <td className="px-4 py-1" key={i}>{value}</td>
                                                            )
                                                        })}
                                                    </tr>
                                                )
                                            })}
                                        </table>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
            </main>
        </div>
    )
}

export default DbSearchResult;
