import axios from "axios";
import Cookies from "js-cookie";
import { API_URL, API_KEY, ROUTES_ID, DATABASE_TYPES_DICT } from "../config";
import { useStore } from '../store/AppContext';
import { useDbSearch } from "./useDbSearch";

export const useConfig = () => {
    const { dispatch, state } = useStore();
    const dbSearchHook = useDbSearch()

    return {
        getConfig: (configKey) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .get(`${API_URL}/config/${configKey}`,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        },
                    })
                .then((res) => {
                    dispatch({
                        type: `SET_${configKey}_CONFIG`,
                        payload: res.data,
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Configuration Retrieval Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
        },
        updateConfig: (configKey, configValue, showmessage = true, navigate = null, navLink = null, message) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .post(`${API_URL}/config/save/${configKey}`, { configValue: configValue },
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        },
                    })
                .then((res) => {
                    dispatch({
                        type: `SET_${configKey}_CONFIG`,
                        payload: configValue,
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    showmessage && dispatch({
                        type: "SET_MESSAGE",
                        payload: message ? message : {
                            type: "success",
                            title: "Saved Successfully",
                            subtitle: "Your changes have been saved successfully"
                        }
                    })
                    
                    if ((configKey === ROUTES_ID.DATABASE_SEARCH) && configValue && configValue.database.length > 0) {
                        let newDBConfig = {
                            database_name: configValue.database[0].databaseName,
                            database_type: configValue.database[0].databaseType.id,
                            project_name: configValue.database[0].projectName,
                            service_account_file: configValue.database[0].serviceAccountFile,
                            table_name: configValue.database[0].databaseName,
                            username: state.user.username,
                            integration: 'local'
                        }
                        switch (configValue.database[0].databaseType.id) {
                            case DATABASE_TYPES_DICT.csv:
                                newDBConfig.csv_file = configValue.database[0].doc_path
                                break
                            case DATABASE_TYPES_DICT.excel:
                                newDBConfig.excel_file = configValue.database[0].doc_path
                                break
                            case DATABASE_TYPES_DICT.mysql:
                                newDBConfig.host = configValue.database[0].host
                                newDBConfig.port = configValue.database[0].port
                                newDBConfig.dbUsername = configValue.database[0].dbUsername
                                newDBConfig.password = configValue.database[0].password
                                break
                            default:
                                break;
                        }
                        dbSearchHook.connectDatabase(true, newDBConfig, navigate, navLink)
                    } else
                        navLink && navigate(navLink);
                })
                .catch((err) => {
                    console.log(err)
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    showmessage && dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Save Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        deleteConfig: (configKey, configValue, showmessage = true, navigate = null, navLink = null) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .post(`${API_URL}/config/delete/${configKey}`, { configValue: configValue },
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        },
                    })
                .then((res) => {
                    dispatch({
                        type: `SET_${configKey}_CONFIG`,
                        payload: res.data,
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    showmessage && dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "success",
                            title: "Saved Successfully",
                            subtitle: "Your changes have been saved successfully"
                        }
                    })
                    navLink && navigate(navLink);
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    showmessage && dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Delete Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        uploadFileBased: (formData, navigate, navLink) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .post(`${API_URL}/user/file_based`, formData,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                .then((res) => {
                    if (res.data) {
                        dispatch({
                            type: `UPDATE_AUTH_CONFIG`,
                            payload: { type: 'file_based', label: 'File Based', value: {} },
                        })
                        dispatch({
                            type: "LOADING",
                            payload: false,
                        })
                        dispatch({
                            type: "SET_MESSAGE",
                            payload: ['success', 'warning'].includes(res.data.status) ?
                                {
                                    type: "success",
                                    title: "Saved Successfully",
                                    subtitle: res.data.message,
                                    close: (res.data.status === 'warning') ? "manual" : ""
                                }
                                :
                                {
                                    type: "fail",
                                    title: "Save Failed",
                                    subtitle: res.data.message
                                }
                        })
                        navLink && navigate(navLink);
                    }
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Save Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        }
    }
}
