import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DbSearchConfigPage from './DbSearchConfigPage';
import DbSearchResult from './DbSearchResult';
import ManageDatabase from './ManageDatabase';

const DbSearchPage = () => {
	return (
		<Routes>
			<Route path='/config' element={<DbSearchConfigPage />} />
			<Route path='/app' element={<DbSearchResult />} />
			<Route path='/manage-databases' element={<ManageDatabase />} />
		</Routes>
	)
}

export default DbSearchPage;
