import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { InputWrapper2 } from '../../components/InputWrapper';
import ProgressSteperWrapper from '../../components/ProgressSteperWrapper';
import { Button, TextButton } from '../../components/Button';
import { useConfig } from "../../hooks/useConfig";
import DBConfigWrapper from "../../components/DBConfigWrapper";
import { useStore } from "../../store/AppContext";
import { ROUTES_ID } from '../../config';
import TooltipWrapper from '../../components/TooltipWrapper';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DbSearchConfigPage = () => {
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };

    const query = useQuery();
    const [tab, setTab] = useState('settings');
    const navigate = useNavigate();
    const configHook = useConfig();
    const { state } = useStore();
    const [dbSearchConfig, setDbSearchConfig] = useState({});
    const [llm, setLLM] = useState(null);
    const [llmList, setLlmList] = useState([]);
    const [gptApiKey, setGptApiKey] = useState('');

    useEffect(() => {
        const tabId = query.get('tabId');
        if (tabId && ['settings'].includes(tabId)) {
            setTab(tabId);
        }
    }, [query]);

    useEffect(() => {
        configHook.getConfig(ROUTES_ID.DATABASE_SEARCH);
    }, [])

    useEffect(() => {
        if (state.dbSearchConfig && Object.keys(state.dbSearchConfig).length > 0) {
            setDbSearchConfig(state.dbSearchConfig);
            state.dbSearchConfig.llm && setLLM(state.dbSearchConfig.llm);
            state.dbSearchConfig.llmList && setLlmList(state.dbSearchConfig.llmList);
            state.dbSearchConfig.gptApiKey && setGptApiKey(state.dbSearchConfig.gptApiKey);
        }
    }, [state.dbSearchConfig])

    useEffect(() => {
        setDbSearchConfig((prevData) => ({ ...prevData, llm: llm }));
    }, [llm])

    useEffect(() => {
        setDbSearchConfig((prevData) => ({ ...prevData, gptApiKey: gptApiKey }));
    }, [gptApiKey])

    useEffect(() => {
        setDbSearchConfig((prevData) => ({ ...prevData, llmList: llmList }));
    }, [llmList])

    const onInstructionsChange = (e) => {
        setDbSearchConfig((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
    }

    const initialNav = () => {
        setDbSearchConfig({});
        navigate('/db-search/app')
    }

    const onSave = () => {
        if(dbSearchConfig?.database[0]?.databaseType?.id === "mysql")
            configHook.updateConfig(ROUTES_ID.DATABASE_SEARCH, dbSearchConfig, true, navigate, '/db-search/app');
    }

    const onManageDBClick = () => {
        navigate('/db-search/manage-databases');
    }

    const step1Content =
        <div className='columns-2 flex justify-center p-4 pt-0'>
            {/* <div className='w-1/2 mr-4'>
                <SelectLLM
                    appId={ROUTES_ID.DATABASE_SEARCH}
                    options={APP_SUPPORT_LLM_TYPES[ROUTES_ID.DATABASE_SEARCH]}
                    llm={llm}
                    setLLM={setLLM}
                    setLlmList={setLlmList}
                    llmList={llmList}
                    gptApiKey={gptApiKey}
                    setGptApiKey={setGptApiKey}
                    isMulti={true}
                />
            </div> */}
            <div className='flex-col w-1/2 p-3 pt-0'>
                <DBConfigWrapper dbConfig={dbSearchConfig} setDBConfig={setDbSearchConfig} index={0} title={`Database`} onSave={onSave}/>
            </div>
            <div className='w-1/2 mt-7 p-6 pt-3'>
                <Button name={"Manage Database/s"} handleFunction={onManageDBClick} type={"outline"} className={'w-full py-[8px]'} />
            </div>
        </div>

    const step2Content =
        <div className='columns-1'>
            <div className='mb-6'>
                <div className='flex items-center  mb-3'>
                    <h5 className="text-gray-dark dark:text-white text-lg font-bold">Instructions</h5>
                    <TooltipWrapper content={"Configure rules for data extraction and generated responses from connected databases."} tootTipStyle={'w-60 mt-2'} autoHide={false} isShowArrow={true}>
                        <div className='flex ml-1 items-center justify-center'>
                            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 2C6.65625 2 4.53125 3.25 3.34375 5.25C2.1875 7.28125 2.1875 9.75 3.34375 11.75C4.53125 13.7812 6.65625 15 9 15C11.3125 15 13.4375 13.7812 14.625 11.75C15.7812 9.75 15.7812 7.28125 14.625 5.25C13.4375 3.25 11.3125 2 9 2ZM9 16.5C6.125 16.5 3.5 15 2.0625 12.5C0.625 10.0312 0.625 7 2.0625 4.5C3.5 2.03125 6.125 0.5 9 0.5C11.8438 0.5 14.4688 2.03125 15.9062 4.5C17.3438 7 17.3438 10.0312 15.9062 12.5C14.4688 15 11.8438 16.5 9 16.5ZM7.75 11H8.5V9H7.75C7.3125 9 7 8.6875 7 8.25C7 7.84375 7.3125 7.5 7.75 7.5H9.25C9.65625 7.5 10 7.84375 10 8.25V11H10.25C10.6562 11 11 11.3438 11 11.75C11 12.1875 10.6562 12.5 10.25 12.5H7.75C7.3125 12.5 7 12.1875 7 11.75C7 11.3438 7.3125 11 7.75 11ZM9 6.5C8.4375 6.5 8 6.0625 8 5.5C8 4.96875 8.4375 4.5 9 4.5C9.53125 4.5 10 4.96875 10 5.5C10 6.0625 9.53125 6.5 9 6.5Z" fill="#788493" />
                            </svg>
                        </div>
                    </TooltipWrapper>
                </div>
                <form onChange={onInstructionsChange}>
                    <InputWrapper2 value={dbSearchConfig.instructions} name={"instructions"} type={"textarea"} placeholder={`Enter your instructions here... \nEx: Please extract policy_ID from database_name if it matches the {email_id} of the tables email_id\nPrint "User Policy:" + policy_ID\nExtract answers from API given the following parameters [param1, param2]\nPrint param1 param2, {response}\nLook up information from {Upload PDF} on Policy_ID`} rows={15} />
                </form>
            </div>
        </div>

    return (
        <div className="flex">
            <main className="w-full mx-auto justify-center content-center items-center place-items-center">
                <div className="flex w-full items-center mt-3 mb-3 pr-6">
                    <button onClick={initialNav} className="flex flex-row fixed text-gray-dark dark:text-white justify-center ml-6 mr-2 items-center">
                        <FontAwesomeIcon className="w-5 mr-2" icon={faArrowLeft} />
                        <p className="text-base font-medium">Back</p>
                    </button>
                    <div className='flex flex-row items-center justify-center w-full'>
                        <TextButton addHandleFunction={() => navigate('/db-search/config?tabId=settings')} label={"Settings"} labelClassName={`border-b-2 pb-3 text-lg font-bold ${(tab === 'settings') ? ' text-gray-dark dark:text-white border-gray-dark dark:border-white ' : ' text-gray border-white dark:border-gray-dark '}`} />
                        {/* <span className='w-6' />
                        <TextButton addHandleFunction={() => navigate('/db-search/config?tabId=integrations')} label={"Integrations"} labelClassName={`border-b-2 pb-3 text-lg font-bold ${(tab === 'integrations') ? ' text-gray-dark dark:text-white border-gray-dark dark:border-white ' : ' text-gray border-white dark:border-gray-dark '}`} /> */}
                    </div>
                </div>
                {(tab === 'settings') &&
                    <ProgressSteperWrapper stepComponents={[step1Content]} initialNav={initialNav} />
                }
                {/* {(tab === 'integrations') &&
                    <Integrations appId={'DB_SEARCH'} integrationsList={[{ id: 'google', comingSoon: false }, { id: 'onedrive', comingSoon: false }]} />
                } */}
            </main>
        </div>
    )
}

export default DbSearchConfigPage;
