import React, { useEffect, useRef, useState } from 'react';
import { InputWrapper, InputWrapper2 } from '../../components/InputWrapper';
import { AddButton, Button, TextButton } from '../../components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import ProgressSteperWrapper from '../../components/ProgressSteperWrapper';
import DBConfigWrapper from '../../components/DBConfigWrapper';
import { useConfig } from '../../hooks/useConfig';
import { useStore } from '../../store/AppContext';
import FileUpload from '../../components/DocSearch/FileUpload';
import SelectLLM from '../../components/SelectLLM';
import { APP_SUPPORT_LLM_TYPES, EMAIL_SERVER_OPTIONS, ROUTES_ID, USER_ROLE } from '../../config';
import TooltipWrapper from '../../components/TooltipWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCaretDown, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Integrations from '../../components/Integrations';

const EmailConfig = () => {
	const useQuery = () => {
		return new URLSearchParams(useLocation().search);
	};

	const query = useQuery();
	const navigate = useNavigate();
	const configHook = useConfig();
	const emailServerDropdownOptionsRef = useRef(null);
	const emailServerDropdownRef = useRef(null);
	const { state, dispatch } = useStore();
	const [tab, setTab] = useState('settings');
	const [coPilotConfig, setCoPilotConfig] = useState(state.coPilotConfig ? state.coPilotConfig : {});
	const [emailConfig, setEmailConfig] = useState({});
	const [llm, setLLM] = useState(null);
	const [llmList, setLlmList] = useState([]);
	const [gpt4ApiKey, setGpt4ApiKey] = useState('');
	const [bisonKeyPath, setBisonKeyPath] = useState('');
	const [apiConfig, setApiConfig] = useState({});
	const [apiKey, setApiKey] = useState('');
	const [AddDataSource, setAddDataSource] = useState([]);
	const [paramsList, setParamsList] = useState([{}, {}]);
	const [dropboxConfig, setDropboxConfig] = useState({});
	const [googleDriveConfig, setGoogleDriveConfig] = useState({});
	const [showEmailServers, setShowEmailServers] = useState('')
	const [viewPassword, setViewPassword] = useState('');

	useEffect(() => {
		const handleClickOutsideEmailServers = (event) => {
			if (emailServerDropdownRef.current && !emailServerDropdownRef.current.contains(event.target) && emailServerDropdownOptionsRef.current && !emailServerDropdownOptionsRef.current.contains(event.target)) {
				setShowEmailServers(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutsideEmailServers);
		return () => document.removeEventListener("mousedown", handleClickOutsideEmailServers);
	}, [emailServerDropdownRef, emailServerDropdownOptionsRef])

	useEffect(() => {
		const tabId = query.get('tabId');
		if (tabId && ['settings', 'integrations'].includes(tabId)) {
			setTab(tabId);
		}
	}, [query]);

	useEffect(() => {
		configHook.getConfig(ROUTES_ID.SERVICE_PILOT);
	}, [])

	useEffect(() => {
		setGpt4ApiKey(null);
		setBisonKeyPath(null);
	}, [llm])

	useEffect(() => {
		if (state.coPilotConfig && Object.keys(state.coPilotConfig).length > 0) {
			setCoPilotConfig(state.coPilotConfig);
			state.coPilotConfig.llm && setLLM(state.coPilotConfig.llm);
			state.coPilotConfig.llmList && setLlmList(state.coPilotConfig.llmList);
			state.coPilotConfig.gptApiKey && setGpt4ApiKey(state.coPilotConfig.gptApiKey);
			state.coPilotConfig.bisonKeyPath && setBisonKeyPath(state.coPilotConfig.bisonKeyPath);
			state.coPilotConfig.apiConfig && setApiConfig(state.coPilotConfig.apiConfig);
			state.coPilotConfig.apiKey && setApiKey(state.coPilotConfig.apiKey);
			state.coPilotConfig.dropboxConfig && setDropboxConfig(state.coPilotConfig.dropboxConfig);
			state.coPilotConfig.googleDriveConfig && setGoogleDriveConfig(state.coPilotConfig.googleDriveConfig);

			const emailConfigTemp = state.coPilotConfig.emailConfig || {};
			setEmailConfig({ ...emailConfigTemp, emailServer: emailConfigTemp.emailServer || EMAIL_SERVER_OPTIONS[0].value });

			if (state.coPilotConfig.apiConfig && Object.keys(state.coPilotConfig.apiConfig).length > 1) {
				var params = new Array(Object.keys(state.coPilotConfig.apiConfig).length - 1);
				params.fill({})
				setParamsList(params);
			}

			if (state.coPilotConfig.database && state.coPilotConfig.database.length > 1) {
				var dbSource = new Array(state.coPilotConfig.database.length - 1);
				dbSource.fill({})
				setAddDataSource(dbSource);
			}
		}
	}, [state.coPilotConfig])

	useEffect(() => {
		setCoPilotConfig((prevData) => ({ ...prevData, emailConfig: emailConfig }));
	}, [emailConfig])

	useEffect(() => {
		setCoPilotConfig((prevData) => ({ ...prevData, llm: llm }));
	}, [llm])

	useEffect(() => {
		setCoPilotConfig((prevData) => ({ ...prevData, llmList: llmList }));
	}, [llmList])

	useEffect(() => {
		setCoPilotConfig((prevData) => ({ ...prevData, gptApiKey: gpt4ApiKey }));
	}, [gpt4ApiKey])

	useEffect(() => {
		setCoPilotConfig((prevData) => ({ ...prevData, bisonKeyPath: bisonKeyPath }));
	}, [bisonKeyPath])

	useEffect(() => {
		setCoPilotConfig((prevData) => ({ ...prevData, apiConfig: apiConfig }));
	}, [apiConfig])

	useEffect(() => {
		setCoPilotConfig((prevData) => ({ ...prevData, apiKey: apiKey }));
	}, [apiKey])

	useEffect(() => {
		setCoPilotConfig((prevData) => ({ ...prevData, dropboxConfig: dropboxConfig }));
	}, [dropboxConfig])

	useEffect(() => {
		setCoPilotConfig((prevData) => ({ ...prevData, googleDriveConfig: googleDriveConfig }));
	}, [googleDriveConfig])

	const handleAddDataSource = () => {
		setAddDataSource((prevElements) => [
			...prevElements, {}
		])
	}

	const handleAddInputParams = () => {
		setParamsList((prevElements) => [
			...prevElements, { id: `param${paramsList.length + 1}`, value: '', label: `Param ${paramsList.length + 1}` }
		])
	}

	const onEmailConfigChange = (e) => {
		setEmailConfig((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
	}

	const onInstructionsChange = (e) => {
		setCoPilotConfig((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
	}

	const initialNav = () => {
		setCoPilotConfig({});
		navigate('/service-pilot/app')
	}

	const validateEmail = (email) => {
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return emailRegex.test(email);
	};

	const onSave = () => {
		const hasPassword = coPilotConfig?.emailConfig?.emailPassword;
		const hasEmailId = coPilotConfig?.emailConfig?.emailId;
		const isEmptyEmailPassword = !hasPassword && !hasEmailId

		if (isEmptyEmailPassword)
			coPilotConfig.emailConfig.emailServer = ""

		const hasValidEmail = hasEmailId && validateEmail(coPilotConfig.emailConfig.emailId)
		const hasValiedEmailConfig = hasValidEmail && hasPassword && coPilotConfig.emailConfig.emailServer

		if (isEmptyEmailPassword || (hasValiedEmailConfig)) {

			const configData = {
				...coPilotConfig, user_id: state.user ? state.user.username : null
			}
			configHook.updateConfig(ROUTES_ID.SERVICE_PILOT, configData, true, navigate, '/service-pilot/app');
		}
		else {
			dispatch({
				type: "SET_MESSAGE",
				payload: {
					type: "fail",
					title: "Service Pilot Configuration Failed",
					subtitle: "Please check your email IMAP configuration!"
				}
			})
		}
	}

	const onManagePdfClick = () => {
		navigate('/service-pilot/manage-documents');
	}

	const onEmailServerChange = (option) => {
		setShowEmailServers(false)
		setEmailConfig((prevData) => ({ ...prevData, ["emailServer"]: option.value }));
	}

	const step1Content =
		<main className="w-full mx-auto pb-6">
			<div className='max-w-lg mx-auto'>
				<h5 className='text-gray-dark dark:text-white text-md font-bold'> Email IMAP Configuration</h5>
				<h6 className='text-gray-light dark:text-gray py-2'>Enter your email information below to configure your email account.</h6>
				<div>
					<div className='relative mt-4'>
						<div ref={emailServerDropdownRef} className={`relative flex items-center justify-between border-2 py-1 px-2 border-white-gray-dark-700 dark:border-gray-medium rounded-lg cursor-pointer`} onClick={() => setShowEmailServers(!showEmailServers)}>
							<>
								<label htmlFor="emailServer" className={`absolute left-2 top-2 z-10 font-medium text-gray-600 transition-all duration-200 transform ${emailConfig.emailServer ? 'translate-y-[-1.25rem] scale-75 bg-white dark:bg-gray-dark px-1' : 'translate-y-0 scale-100 bg-none pl-1 mt-1 cursor-pointer'}`}> {emailConfig.emailServer ? "Email Server" : 'Select Email Server'}</label>
								<p className="h-9 px-[4px] py-[6px] text-gray-dark dark:text-white text-base text-center font-normal">{emailConfig.emailServer ? EMAIL_SERVER_OPTIONS.find(option => option.id === emailConfig.emailServer)?.label : ''}</p>
							</>
							<FontAwesomeIcon className="mx-2 text-gray-dark dark:text-white text-xl text-center" icon={faCaretDown} />
						</div>
						{showEmailServers &&
							<div ref={emailServerDropdownOptionsRef} className={`w-full max-h-52 p-2 z-10 absolute right-0 rounded-lg bg-gray-900 dark:bg-gray-black hide-scrollbar overflow-auto`}>
								{EMAIL_SERVER_OPTIONS.map((option, index) => {
									return <div id={option.id} onClick={() => onEmailServerChange(option)} className={`py-2 px-2 text-gray-dark cursor-pointer dark:text-white w-full flex justify-start hover:bg-white-gray-900 hover:dark:bg-gray-200`} key={index} value={option.value}>{option.label}</div>
								})}
							</div>
						}
					</div>
					<form onChange={onEmailConfigChange}>
						<InputWrapper containerStyle={"mt-6"} value={emailConfig.emailId ? emailConfig.emailId : ''} name={"emailId"} label={"Email ID"} type={"text"} />
						<InputWrapper containerStyle={"mt-6"} value={emailConfig.emailPassword ? emailConfig.emailPassword : ''} label={"Password"} name={"emailPassword"} type={viewPassword ? "text" : "password"} autoComplete={"new-password"} rightIcon={emailConfig.emailPassword && <FontAwesomeIcon icon={viewPassword ? faEye : faEyeSlash} onClick={() => setViewPassword(!viewPassword)} className="text-gray-dark dark:text-white cursor-pointer" />} />
					</form>
					<p className='text-gray text-sm'>For more information on retrieving your App Password, please visit the following <a href='https://knowledge.workspace.google.com/kb/how-to-create-app-passwords-000009237' target="_blank" rel='noreferrer' className='text-blue underline'>link</a>.</p>
				</div>
			</div>
		</main>

	const step2Content =
		<div className='flex justify-center'>
			<div className='w-1/2 mr-4'>
				{[USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN].includes(state.userRole) &&
					<SelectLLM
						appId={ROUTES_ID.SERVICE_PILOT}
						options={APP_SUPPORT_LLM_TYPES[ROUTES_ID.SERVICE_PILOT]}
						llm={llm}
						setLLM={setLLM}
						setLlmList={setLlmList}
						llmList={llmList}
						path={'/co_pilot/bison'}
						gptApiKey={gpt4ApiKey}
						setGptApiKey={setGpt4ApiKey}
						setBisonKeyPath={setBisonKeyPath}
						isMulti={true}
					/>
				}
				{/* <h5 className='text-gray-dark dark:text-white text-md font-bold mt-8'>API Endpoint</h5>
				<form onChange={onApiConfigChange}>
					<InputWrapper containerStyle={"mt-4"} value={apiConfig.url ? apiConfig.url : ''} name={"url"} label={"URL"} type={"text"} />
					{paramsList.map((Item, key) => (
						<InputWrapper containerStyle={"mt-4"} key={key} name={`param${key + 1}`} value={apiConfig[`param${key + 1}`] ? apiConfig[`param${key + 1}`] : ''} label={`Param ${key + 1}`} type={"text"} />
					))}
				</form>
				<AddButton label={"Add Parameter"} addHandleFunction={handleAddInputParams} />
				<InputWrapper containerStyle={"mt-4"} value={apiKey} setValue={setApiKey} name={"apiKey"} label={"API Key"} type={"text"} /> */}
				<div className='w-full mt-12 flex gap-4'>
					<FileUpload appId={ROUTES_ID.SERVICE_PILOT} allowMultiple={true} folder={true} path={"co_pilot"} />
					<FileUpload appId={ROUTES_ID.SERVICE_PILOT} allowMultiple={true} path={"co_pilot"} />
				</div>
				<div className='pt-6'>
					<Button name={"Manage Document/s"} handleFunction={onManagePdfClick} type={"outline"} className={'w-full py-[10px]'} />
				</div>
			</div>
			{/* <div className='w-1/2 ml-8'>
				<DBConfigWrapper initialConfig={(coPilotConfig.database && (coPilotConfig.database.length > 0)) ? coPilotConfig.database[0] : {}} dbConfig={coPilotConfig} setDBConfig={setCoPilotConfig} index={0} title={`Database${AddDataSource.length > 0 ? ' 1' : ''}`} />
				{AddDataSource.map((item, key) => (
					<DBConfigWrapper key={key} initialConfig={(coPilotConfig.database && (coPilotConfig.database.length > (key + 1))) ? coPilotConfig.database[key + 1] : {}} dbConfig={coPilotConfig} index={key + 1} setDBConfig={setCoPilotConfig} title={`Database ${key + 2}`} />
				))}
				<AddButton label={"Add more data sources"} addHandleFunction={handleAddDataSource} />
			</div> */}
		</div>


	const step3Content =
		<>
			<h1 className="text-gray-dark dark:text-white text-xl font-bold mb-3">Instructions</h1>
			<div className='columns-1'>
				<div className='w-100'>
					<div className='flex items-center mb-3'>
						<h5 className="text-gray-dark dark:text-white text-lg font-normal ">Database Instructions</h5>
						<TooltipWrapper content={"Configure rules for data extraction from connected databases."} tootTipStyle={'w-60 mt-2'} autoHide={false} isShowArrow={true}>
							<div className='flex ml-1 items-center justify-center'>
								<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M9 2C6.65625 2 4.53125 3.25 3.34375 5.25C2.1875 7.28125 2.1875 9.75 3.34375 11.75C4.53125 13.7812 6.65625 15 9 15C11.3125 15 13.4375 13.7812 14.625 11.75C15.7812 9.75 15.7812 7.28125 14.625 5.25C13.4375 3.25 11.3125 2 9 2ZM9 16.5C6.125 16.5 3.5 15 2.0625 12.5C0.625 10.0312 0.625 7 2.0625 4.5C3.5 2.03125 6.125 0.5 9 0.5C11.8438 0.5 14.4688 2.03125 15.9062 4.5C17.3438 7 17.3438 10.0312 15.9062 12.5C14.4688 15 11.8438 16.5 9 16.5ZM7.75 11H8.5V9H7.75C7.3125 9 7 8.6875 7 8.25C7 7.84375 7.3125 7.5 7.75 7.5H9.25C9.65625 7.5 10 7.84375 10 8.25V11H10.25C10.6562 11 11 11.3438 11 11.75C11 12.1875 10.6562 12.5 10.25 12.5H7.75C7.3125 12.5 7 12.1875 7 11.75C7 11.3438 7.3125 11 7.75 11ZM9 6.5C8.4375 6.5 8 6.0625 8 5.5C8 4.96875 8.4375 4.5 9 4.5C9.53125 4.5 10 4.96875 10 5.5C10 6.0625 9.53125 6.5 9 6.5Z" fill="#788493" />
								</svg>
							</div>
						</TooltipWrapper>
					</div>
					<form onChange={onInstructionsChange}>
						<InputWrapper2 value={coPilotConfig.dbInstructions} name={"dbInstructions"} type={"textarea"} placeholder={`Enter your instructions here... \nEx: Please extract policy_ID from table_name if it matches the {email_id} of the tables email_id\nPrint "User Policy:" + policy_ID\nExtract answers from API given the following parameters [param1, param2]\nPrint param1 param2, {response}\nLook up information from {Upload PDF} on Policy_ID`} rows={10} />
					</form>
				</div>
			</div>
			<div className='columns-1 mt-6'>
				<div className='w-100'>
					<div className='flex items-center mb-3'>
						<h5 className="text-gray-dark dark:text-white text-lg font-normal">Vector Database Instructions</h5>
						<TooltipWrapper content={"Configure rules for data extraction from uploaded documents."} tootTipStyle={'w-60 mt-2'} autoHide={false} isShowArrow={true}>
							<div className='flex ml-1 items-center justify-center'>
								<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M9 2C6.65625 2 4.53125 3.25 3.34375 5.25C2.1875 7.28125 2.1875 9.75 3.34375 11.75C4.53125 13.7812 6.65625 15 9 15C11.3125 15 13.4375 13.7812 14.625 11.75C15.7812 9.75 15.7812 7.28125 14.625 5.25C13.4375 3.25 11.3125 2 9 2ZM9 16.5C6.125 16.5 3.5 15 2.0625 12.5C0.625 10.0312 0.625 7 2.0625 4.5C3.5 2.03125 6.125 0.5 9 0.5C11.8438 0.5 14.4688 2.03125 15.9062 4.5C17.3438 7 17.3438 10.0312 15.9062 12.5C14.4688 15 11.8438 16.5 9 16.5ZM7.75 11H8.5V9H7.75C7.3125 9 7 8.6875 7 8.25C7 7.84375 7.3125 7.5 7.75 7.5H9.25C9.65625 7.5 10 7.84375 10 8.25V11H10.25C10.6562 11 11 11.3438 11 11.75C11 12.1875 10.6562 12.5 10.25 12.5H7.75C7.3125 12.5 7 12.1875 7 11.75C7 11.3438 7.3125 11 7.75 11ZM9 6.5C8.4375 6.5 8 6.0625 8 5.5C8 4.96875 8.4375 4.5 9 4.5C9.53125 4.5 10 4.96875 10 5.5C10 6.0625 9.53125 6.5 9 6.5Z" fill="#788493" />
								</svg>
							</div>
						</TooltipWrapper>
					</div>
					<form onChange={onInstructionsChange}>
						<InputWrapper2 value={coPilotConfig.vectorInstructions} name={"vectorInstructions"} type={"textarea"} placeholder={`Enter your instructions here... \nEx: Please extract policy_ID from table_name if it matches the {email_id} of the tables email_id\nPrint "User Policy:" + policy_ID\nExtract answers from API given the following parameters [param1, param2]\nPrint param1 param2, {response}\nLook up information from {Upload PDF} on Policy_ID`} rows={10} />
					</form>
				</div>
			</div>
			<div className='columns-1 mt-6 mb-3'>
				<div className='w-100'>
					<div className='flex items-center mb-3'>
						<h5 className="text-gray-dark dark:text-white text-lg font-normal ">Email Generation Instructions</h5>
						<TooltipWrapper content={"Configure rules for generated email responses."} tootTipStyle={'w-60 mt-2'} autoHide={false} isShowArrow={true}>
							<div className='flex ml-1 items-center justify-center'>
								<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M9 2C6.65625 2 4.53125 3.25 3.34375 5.25C2.1875 7.28125 2.1875 9.75 3.34375 11.75C4.53125 13.7812 6.65625 15 9 15C11.3125 15 13.4375 13.7812 14.625 11.75C15.7812 9.75 15.7812 7.28125 14.625 5.25C13.4375 3.25 11.3125 2 9 2ZM9 16.5C6.125 16.5 3.5 15 2.0625 12.5C0.625 10.0312 0.625 7 2.0625 4.5C3.5 2.03125 6.125 0.5 9 0.5C11.8438 0.5 14.4688 2.03125 15.9062 4.5C17.3438 7 17.3438 10.0312 15.9062 12.5C14.4688 15 11.8438 16.5 9 16.5ZM7.75 11H8.5V9H7.75C7.3125 9 7 8.6875 7 8.25C7 7.84375 7.3125 7.5 7.75 7.5H9.25C9.65625 7.5 10 7.84375 10 8.25V11H10.25C10.6562 11 11 11.3438 11 11.75C11 12.1875 10.6562 12.5 10.25 12.5H7.75C7.3125 12.5 7 12.1875 7 11.75C7 11.3438 7.3125 11 7.75 11ZM9 6.5C8.4375 6.5 8 6.0625 8 5.5C8 4.96875 8.4375 4.5 9 4.5C9.53125 4.5 10 4.96875 10 5.5C10 6.0625 9.53125 6.5 9 6.5Z" fill="#788493" />
								</svg>
							</div>
						</TooltipWrapper>
					</div>
					<form onChange={onInstructionsChange}>
						<InputWrapper2 value={coPilotConfig.emailGenerationInstructions} name={"emailGenerationInstructions"} type={"textarea"} placeholder={`Enter your instructions here... \nEx: Please extract policy_ID from table_name if it matches the {email_id} of the tables email_id\nPrint "User Policy:" + policy_ID\nExtract answers from API given the following parameters [param1, param2]\nPrint param1 param2, {response}\nLook up information from {Upload PDF} on Policy_ID`} rows={10} />
					</form>
				</div>
			</div>
		</>

	return (
		<div className="flex">
			<main className="w-full mx-auto justify-center content-center items-center place-items-center">
				<div className="flex w-full items-center mt-3 mb-6 pr-6">
					<button onClick={initialNav} className="flex flex-row fixed text-gray-dark dark:text-white justify-center ml-6 mr-2 items-center">
						<FontAwesomeIcon className="w-5 mr-2" icon={faArrowLeft} />
						<p className="text-base font-medium">Back</p>
					</button>
					<div className='flex flex-row items-center justify-center w-full'>
						<TextButton addHandleFunction={() => navigate('/service-pilot/config?tabId=settings')} label={"Settings"} labelClassName={`border-b-2 pb-3 text-lg font-bold ${(tab === 'settings') ? ' text-gray-dark dark:text-white border-gray-dark dark:border-white ' : ' text-gray border-white dark:border-gray-dark '}`} />
						<span className='w-6' />
						<TextButton addHandleFunction={() => navigate('/service-pilot/config?tabId=integrations')} label={"Integrations"} labelClassName={`border-b-2 pb-3 text-lg font-bold ${(tab === 'integrations') ? ' text-gray-dark dark:text-white border-gray-dark dark:border-white ' : ' text-gray border-white dark:border-gray-dark '}`} />
					</div>
				</div>
				{(tab === 'settings') &&
					<ProgressSteperWrapper stepComponents={[USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN].includes(state.userRole) ? [step1Content, step2Content] : [step2Content]} initialNav={initialNav} onSave={onSave} />
				}
				{(tab === 'integrations') &&
					<Integrations appId={ROUTES_ID.SERVICE_PILOT} integrationsList={[{ id: 'google', comingSoon: false }, { id: 'onedrive', comingSoon: false }]} />
				}
			</main>
		</div>
	)
}

export default EmailConfig;
