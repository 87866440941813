import moment from 'moment';
import React from 'react';
import { DATABASE_TYPES, INTEGRATION_TYPES } from '../config';

const Table = ({ data, columns, className }) => {
	
	return (
		<table className="min-w-full text-gray-dark-500 dark:text-white text-left text-sm font-light">
			<thead className="block border-b border-t font-medium dark:border-gray">
				<tr className='flex items-center'>
					{columns.map((headerColumn, key) => (
						<th style={{ display: 'flex', flexDirection: 'row', flex: headerColumn.flex, textAlign: headerColumn.textAlign }} scope="col" className={`flex py-4 ${headerColumn.headerClassName}`} key={key}>{headerColumn.titleComponent ? headerColumn.titleComponent() : headerColumn.title}</th>
					))}
				</tr>
			</thead>
			<tbody className={`block w-full ${className ? className : 'content-container-with-top-bar'} overflow-y-auto`}>
				{data && data.map((data, key) => (
					<tr className={`w-full flex items-center border-b dark:border-gray cursor-pointer hover:bg-gray-900 hover:dark:bg-gray-300`} key={key}>
						{columns.map((dataColumn, key1) => (
							<td style={{ flex: dataColumn.flex, textAlign: dataColumn.textAlign, paddingBottom: dataColumn.padding, paddingTop: dataColumn.padding }} className={`flex ${dataColumn.dataClassName} text-nowrap overflow-hidden`} key={key1}>
								{dataColumn.id === 'CUSTOM_COMPONENT' ?
									dataColumn.component(data)
									:
									(dataColumn.id === 'status' ?
										<p className={`font-medium ${data[dataColumn.id] === 'Completed' && 'text-status-green'} ${data[dataColumn.id] === 'Corrupted' && 'text-status-red'} ${data[dataColumn.id] === 'Processing' && 'text-status-blue'} ${data[dataColumn.id] === 'Initial' && 'text-status-gray'} ${data[dataColumn.id] === 'Not found' && 'text-status-light-red'}`}>
											{data[dataColumn.id] ? data[dataColumn.id] : '-'}
										</p>
										:
										(dataColumn.id === 'integration' ?
											(INTEGRATION_TYPES[data[dataColumn.id]] ?
												<div className={`relative md:flex w-5 h-6 justify-center items-center`}>
													{INTEGRATION_TYPES[data[dataColumn.id]].icon}
												</div>
												:
												<p>-</p>
											)
											:
											(dataColumn.id === 'time' ?
												moment(data[dataColumn.id] * 1000).format('DD/MM/YYYY, h:mm a')
												:
												(dataColumn.id === 'database_type' ?
													<p className={`font-medium}`}>
														{DATABASE_TYPES.find(db => db.id === data[dataColumn.id])?.label}
													</p>
													:
													<div className='mr-2 w-full overflow-auto'><p className={`max-w-96 text-nowrap`}>{data[dataColumn.id]}</p></div>
												)
											)
										)
									)
								}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	)
}

export default Table;
