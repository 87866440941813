import React, { useEffect, useRef, useState } from 'react';
import { useStore } from '../store/AppContext';
import { useNavigate } from 'react-router-dom';
import Table from "./Table";
import { TextButton } from './Button';
import { CheckBox } from "./CheckBox";
import { InputWrapper } from "./InputWrapper";
import BottomBar from "./Home/BottomBar";
import { useUserGroup } from "../hooks/useUserGroup";
import { useUser } from "../hooks/useUser";
import { APPS_ID, PAGE_SIZES, ROUTES_ID } from '../config';
import Pagination from './Pagination';
import { useApplication } from '../hooks/useApplication';
import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';

const ApplicationsPermissionWrapper = ({ isAuthStack }) => {
	const navigate = useNavigate();
	const userGroupHook = useUserGroup();
	const userHook = useUser();
	const applicationHook = useApplication();
	const userGroupPagesizesDropdownRef = useRef(null);
	const userGroupPageSizesDropdownOptionsRef = useRef(null);
	const userPagesizesDropdownRef = useRef(null);
	const userPageSizesDropdownOptionsRef = useRef(null);
	const { state } = useStore();
	const [userGroupPageSize, setUserGroupPageSize] = useState(PAGE_SIZES[PAGE_SIZES.length - 1]);
	const [userGroupPageNum, setUserGroupPageNum] = useState(0);
	const [showUserGroupPageSizes, setShowUserGroupPageSizes] = useState(false);
	const [userPageSize, setUserPageSize] = useState(PAGE_SIZES[PAGE_SIZES.length - 1]);
	const [userPageNum, setUserPageNum] = useState(0);
	const [showUserPageSizes, setShowUserPageSizes] = useState(false);
	const [userGroupInfo, setUserGroupInfo] = useState([]);
	const [userInfo, setUserInfo] = useState([]);
	const [tab, setTab] = useState('user_groups');
	const [searchKey, setSearchkey] = useState('');
	const [selectedUserGroups, setSelectedUserGroups] = useState(state.appsPermissionUserGroups);
	const [selectedUsers, setSelectedUsers] = useState(state.appsPermissionUsers);

	useEffect(() => {
		applicationHook.getAppsPermission();
	}, [])

	useEffect(() => {
		setSelectedUsers(state.appsPermissionUsers);
	}, [state.appsPermissionUsers])

	useEffect(() => {
		setSelectedUserGroups(state.appsPermissionUserGroups);
	}, [state.appsPermissionUserGroups])

	useEffect(() => {
		(userGroupPageNum > 0) && userGroupHook.getUserGroupList(userGroupPageNum, userGroupPageSize);
	}, [userGroupPageNum])

	useEffect(() => {
		if (userGroupPageNum === 1)
			userGroupHook.getUserGroupList(1, userGroupPageSize);
		else
			setUserGroupPageNum(1);
	}, [userGroupPageSize])

	useEffect(() => {
		const handleClickOutsideShowPageSizes = (event) => {
			if (userGroupPagesizesDropdownRef.current && !userGroupPagesizesDropdownRef.current.contains(event.target) && userGroupPageSizesDropdownOptionsRef.current && !userGroupPageSizesDropdownOptionsRef.current.contains(event.target)) {
				setShowUserGroupPageSizes(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutsideShowPageSizes);
		return () => document.removeEventListener("mousedown", handleClickOutsideShowPageSizes);
	}, [userGroupPagesizesDropdownRef, userGroupPageSizesDropdownOptionsRef])

	useEffect(() => {
		(userPageNum > 0) && userHook.getAllUsers(userPageNum, userPageSize);
	}, [userPageNum])

	useEffect(() => {
		if (userPageNum === 1)
			userHook.getAllUsers(1, userPageSize);
		else
			setUserPageNum(1);
	}, [userPageSize])

	useEffect(() => {
		const handleClickOutsideShowPageSizes = (event) => {
			if (userPagesizesDropdownRef.current && !userPagesizesDropdownRef.current.contains(event.target) && userPageSizesDropdownOptionsRef.current && !userPageSizesDropdownOptionsRef.current.contains(event.target)) {
				setShowUserPageSizes(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutsideShowPageSizes);
		return () => document.removeEventListener("mousedown", handleClickOutsideShowPageSizes);
	}, [userPagesizesDropdownRef, userPageSizesDropdownOptionsRef])

	useEffect(() => {
		setSearchkey('');
		setShowUserGroupPageSizes(false);
		setShowUserPageSizes(false);
	}, [tab])

	useEffect(() => {
		setSearchkey('');
	}, [userGroupPageNum, userGroupPageSize, userPageNum, userPageSize])

	useEffect(() => {
		if (searchKey) {
			const regex = new RegExp(searchKey.split('').join('.*'), 'i');
			if ((tab === 'user_groups') && state.userGroupInfo) {
				let groupsInfo = JSON.parse(JSON.stringify(state.userGroupInfo));
				const list = groupsInfo.groups.filter(group => group.group_name && regex.test(group.group_name.toLocaleLowerCase()));
				groupsInfo.groups = list;
				setUserGroupInfo(groupsInfo);
			} else if ((tab === 'users') && state.userInfo) {
				let usersInfo = JSON.parse(JSON.stringify(state.userInfo));
				const list = usersInfo.users.filter(user => user.full_name && regex.test(user.full_name.toLocaleLowerCase()));
				usersInfo.users = list;
				setUserInfo(usersInfo);
			}
		} else {
			setUserGroupInfo(state.userGroupInfo);
			setUserInfo(state.userInfo);
		}
	}, [searchKey])

	useEffect(() => {
		if (state.userGroupInfo)
			setUserGroupInfo(state.userGroupInfo);
	}, [state.userGroupInfo])

	useEffect(() => {
		if (state.userInfo)
			setUserInfo(state.userInfo);
	}, [state.userInfo])

	const onSelectUserGroups = (data, appId) => {
		let groupList = selectedUserGroups[appId] ? selectedUserGroups[appId] : [];
		const index = groupList.indexOf(data.group_id);

		if (index > -1)
			groupList.splice(index, 1);
		else
			groupList.push(data.group_id);

		setSelectedUserGroups({ ...selectedUserGroups, [appId]: groupList });
	}

	const onSelectUsers = (data, appId) => {
		let userList = selectedUsers[appId] ? selectedUsers[appId] : [];
		const index = userList.findIndex(user => user.username === data.username);

		if (index > -1)
			userList.splice(index, 1);
		else
			userList.push({ username: data.username, user_groups: data.user_groups });

		setSelectedUsers({ ...selectedUsers, [appId]: userList });
	}

	const getIcon = (data, tab, appId) => {
		let icon = faCheck;

		if (tab === 'user_groups' && selectedUserGroups[appId] && !selectedUserGroups[appId].includes(data.group_id) && selectedUsers[appId] && selectedUsers[appId].find(user => user.user_groups === data.group_id))
			icon = faMinus;

		return icon;
	}

	const checkboxComponent = (data, appId, tab) => {
		return (
			<CheckBox
				value={tab === 'user_groups' ? ((selectedUserGroups[appId] && selectedUserGroups[appId].includes(data.group_id)) || (selectedUsers[appId] && selectedUsers[appId].find(user => user.user_groups === data.group_id))) : ((selectedUserGroups[appId] && selectedUserGroups[appId].includes(data.user_groups)) || (selectedUsers[appId] && selectedUsers[appId].find(user => user.username === data.username)))}
				setValue={() => tab === 'user_groups' ? onSelectUserGroups(data, appId) : onSelectUsers(data, appId)}
				name={appId}
				icon={getIcon(data, tab, appId)}
				className={'w-full my-[8px] flex items-center justify-center'}
				disabled={(tab === 'users') && selectedUserGroups[appId] && selectedUserGroups[appId].includes(data.user_groups)}
			/>
		)
	}

	const user_group_columns = [
		{
			id: "group_name",
			title: "User Group",
			flex: 0.7,
			headerClassName: 'pl-3',
			dataClassName: 'pl-3'
		},
		{
			id: "CUSTOM_COMPONENT",
			title: "Service Pilot",
			component: data => checkboxComponent(data, ROUTES_ID.SERVICE_PILOT, 'user_groups'),
			flex: 0.15,
			textAlign: 'center',
			headerClassName: 'items-center justify-center'
		},
		{
			id: "CUSTOM_COMPONENT",
			title: "Database Search",
			component: data => checkboxComponent(data, ROUTES_ID.DATABASE_SEARCH, 'user_groups'),
			flex: 0.15,
			textAlign: 'center',
			headerClassName: 'items-center justify-center'
		},
		{
			id: "CUSTOM_COMPONENT",
			title: "Document Search",
			component: data => checkboxComponent(data, ROUTES_ID.DOCUMENT_SEARCH, 'user_groups'),
			flex: 0.15,
			textAlign: 'center',
			headerClassName: 'items-center justify-center'
		}
	]

	const user_columns = [
		{
			id: "full_name",
			title: "Name",
			flex: 0.35,
			headerClassName: 'pl-3',
			dataClassName: 'pl-3'
		},
		{
			id: "user_groups",
			title: "User Group",
			flex: 0.35
		},
		{
			id: "CUSTOM_COMPONENT",
			title: "Service Pilot",
			component: data => checkboxComponent(data, ROUTES_ID.SERVICE_PILOT, 'users'),
			flex: 0.15,
			textAlign: 'center',
			headerClassName: 'items-center justify-center'
		},
		{
			id: "CUSTOM_COMPONENT",
			title: "Database Search",
			component: data => checkboxComponent(data, ROUTES_ID.DATABASE_SEARCH, 'users'),
			flex: 0.15,
			textAlign: 'center',
			headerClassName: 'items-center justify-center'
		},
		{
			id: "CUSTOM_COMPONENT",
			title: "Document Search",
			component: data => checkboxComponent(data, ROUTES_ID.DOCUMENT_SEARCH, 'users'),
			flex: 0.15,
			textAlign: 'center',
			headerClassName: 'items-center justify-center'
		}
	]

	const handleSave = () => {
		const data = {
			appIds: APPS_ID,
			usersList: selectedUsers,
			userGroupsList: selectedUserGroups
		}

		applicationHook.addPermissionsToApplications(data, navigate, isAuthStack ? '/doc-search/app' : null);
	}

	const handleSkip = () => {
		navigate('/doc-search/app');
	}

	const ButtonList = isAuthStack ?
		[
			{
				"name": "Skip",
				"handleFunction": handleSkip,
				"disabled": false,
				"type": "outline"
			},
			{
				"name": "Save",
				"handleFunction": handleSave,
				"disabled": !((selectedUserGroups[ROUTES_ID.SERVICE_PILOT].length > 0) || (selectedUserGroups[ROUTES_ID.DATABASE_SEARCH].length > 0) || (selectedUserGroups[ROUTES_ID.DOCUMENT_SEARCH].length > 0) || (selectedUsers[ROUTES_ID.SERVICE_PILOT].length > 0) || (selectedUsers[ROUTES_ID.DATABASE_SEARCH].length > 0) || (selectedUsers[ROUTES_ID.DOCUMENT_SEARCH].length > 0))
			}
		]
		:
		[
			{
				"name": "Save",
				"handleFunction": handleSave,
				"disabled": !((selectedUserGroups[ROUTES_ID.SERVICE_PILOT].length > 0) || (selectedUserGroups[ROUTES_ID.DATABASE_SEARCH].length > 0) || (selectedUserGroups[ROUTES_ID.DOCUMENT_SEARCH].length > 0) || (selectedUsers[ROUTES_ID.SERVICE_PILOT].length > 0) || (selectedUsers[ROUTES_ID.DATABASE_SEARCH].length > 0) || (selectedUsers[ROUTES_ID.DOCUMENT_SEARCH].length > 0))
			}
		]

	return (
		<div className="h-screen bg-white dark:bg-gray-dark">
			<div className='px-6'>
				<div className="flex w-full justify-between mt-3 mb-6 pr-6">
					<div className='flex flex-row items-center w-1/2'>
						<TextButton addHandleFunction={() => setTab('user_groups')} label={"User groups"} labelClassName={`border-b-2 pb-3 text-lg font-bold ${(tab === 'user_groups') ? ' text-gray-dark dark:text-white border-gray-dark dark:border-white ' : ' text-gray border-white dark:border-gray-dark '}`} />
						<span className='w-6' />
						<TextButton addHandleFunction={() => setTab('users')} label={"Users"} labelClassName={`border-b-2 pb-3 text-lg font-bold ${(tab === 'users') ? ' text-gray-dark dark:text-white border-gray-dark dark:border-white ' : ' text-gray border-white dark:border-gray-dark '}`} />
					</div>
					<div className='flex flex-row justify-end w-1/2'>
						<InputWrapper value={searchKey} setValue={setSearchkey} name={"emailServer"} label={tab === 'user_groups' ? "Search User Groups" : "Search Users"} type={"text"} />
					</div>
				</div>
				<>
					{tab === 'user_groups' &&
						(userGroupInfo && userGroupInfo.groups && userGroupInfo.groups.length > 0 ?
							<>
								<Table columns={user_group_columns} data={userGroupInfo.groups} className={isAuthStack ? 'user-access-auth-stack-content-container' : 'user-access-content-container'} />
								<Pagination
									pagesizesDropdownRef={userGroupPagesizesDropdownRef}
									pageSizesDropdownOptionsRef={userGroupPageSizesDropdownOptionsRef}
									pageSizes={PAGE_SIZES}
									pageNum={userGroupPageNum}
									setPageNum={setUserGroupPageNum}
									pageSize={userGroupPageSize}
									setPageSize={setUserGroupPageSize}
									showPageSizes={showUserGroupPageSizes}
									setShowPageSizes={setShowUserGroupPageSizes}
									total={userGroupInfo.total}
								/>
							</>
							:
							<p className={`${isAuthStack ? 'empty-user-access-auth-stack-content-container' : ''} w-full flex items-center justify-center mt-12 text-gray-dark dark:text-white`}>
								{searchKey ? 'No matching groups found.' : 'You don\'t have any user Groups'}
							</p>
						)
					}
					{tab === 'users' &&
						(userInfo && userInfo.users && userInfo.users.length > 0 ?
							<>
								<Table columns={user_columns} data={userInfo.users} className={isAuthStack ? 'user-access-auth-stack-content-container' : 'user-access-content-container'} />
								<Pagination
									pagesizesDropdownRef={userPagesizesDropdownRef}
									pageSizesDropdownOptionsRef={userPageSizesDropdownOptionsRef}
									pageSizes={PAGE_SIZES}
									pageNum={userPageNum}
									setPageNum={setUserPageNum}
									pageSize={userPageSize}
									setPageSize={setUserPageSize}
									showPageSizes={showUserPageSizes}
									setShowPageSizes={setShowUserPageSizes}
									total={userInfo.total}
								/>
							</>
							:
							<p className={`${isAuthStack ? 'empty-user-access-auth-stack-content-container' : ''} w-full flex items-center justify-center mt-12 text-gray-dark dark:text-white`}>
								{searchKey ? 'No matching users found.' : 'You don\'t have any users'}
							</p>
						)
					}
				</>
			</div>
			{(isAuthStack || (tab === 'users' && userInfo.users && userInfo.users.length > 0) || (tab === 'user_groups' && userGroupInfo.groups && userGroupInfo.groups.length > 0)) &&
				<BottomBar ButtonList={ButtonList} />
			}
		</div>
	)
}

export default ApplicationsPermissionWrapper;
