import React, { useEffect, useRef, useState } from 'react';
import TableEmail from '../../components/TableEmail';
import { useCoPilot } from '../../hooks/useCoPilot';
import { useStore } from '../../store/AppContext';
import Pagination from '../../components/Pagination';
import { PAGE_SIZES } from '../../config';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';

const EmailCoPilot = () => {
	const navigate = useNavigate();
	const coPilotHook = useCoPilot();
	const emailsPagesizesDropdownRef = useRef(null);
	const emailsPageSizesDropdownOptionsRef = useRef(null);
	const { state } = useStore();
	const [emailsPageSize, setEmailsPageSize] = useState(PAGE_SIZES[1]);
	const [emailsPageNum, setEmailsPageNum] = useState(0);
	const [showEmailsPageSizes, setShowUserPageSizes] = useState(false);

	useEffect(() => {
		(emailsPageNum > 0) && coPilotHook.getEmails(emailsPageNum, emailsPageSize);
	}, [emailsPageNum])

	useEffect(() => {
		if (emailsPageNum === 1)
			coPilotHook.getEmails(1, emailsPageSize);
		else
			setEmailsPageNum(1);
	}, [emailsPageSize])

	useEffect(() => {
		const handleClickOutsideShowPageSizes = (event) => {
			if (emailsPagesizesDropdownRef.current && !emailsPagesizesDropdownRef.current.contains(event.target) && emailsPageSizesDropdownOptionsRef.current && !emailsPageSizesDropdownOptionsRef.current.contains(event.target)) {
				setShowUserPageSizes(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutsideShowPageSizes);
		return () => document.removeEventListener("mousedown", handleClickOutsideShowPageSizes);
	}, [emailsPagesizesDropdownRef, emailsPageSizesDropdownOptionsRef])

	const columns = [
		{
			id: "sender_name",
			title: "Sender Name",
			flex: 0.25
		},
		{
			id: "subject",
			title: "Email Subject",
			flex: 0.6
		},
		{
			id: "send_date",
			title: "Time",
			flex: 0.15
		}
	]

	return (
		<div>
			<main className="content-container-with-top-bar mx-auto px-6">
				<div className='text-gray-dark-200 dark:text-gray-800 text-sm mb-6'>
					<p>
						Connect your email account and let Generate help you by summarizing emails and crafting responses tailored to each email's context.
					</p>
				</div>
				{state.coPilotEmailsInfo && state.coPilotEmailsInfo.emails && (state.coPilotEmailsInfo.emails.length > 0) ?
					<>
						<h2 className='mb-4 font-semibold text-xl text-gray-dark dark:text-white'>Inbox</h2>
						<TableEmail data={state.coPilotEmailsInfo.emails} columns={columns} className={'emails-content-container'} />
						<Pagination
							pagesizesDropdownRef={emailsPagesizesDropdownRef}
							pageSizesDropdownOptionsRef={emailsPageSizesDropdownOptionsRef}
							pageSizes={PAGE_SIZES}
							pageNum={emailsPageNum}
							setPageNum={setEmailsPageNum}
							pageSize={emailsPageSize}
							setPageSize={setEmailsPageSize}
							showPageSizes={showEmailsPageSizes}
							setShowPageSizes={setShowUserPageSizes}
							total={state.coPilotEmailsInfo.total}
						/>
					</>
					:
					<div className="w-full emails-content-container flex flex-col items-center justify-center my-6 text-gray-dark dark:text-white">
						<div className='w-1/2 flex flex-col items-center justify-center'>
							<FontAwesomeIcon className='h-20 w-20' icon={faMailBulk} />
							<p className='text-center my-6'>Get started by connecting your email account to Generate</p>
							<Button type={'outline'} handleFunction={() => navigate('/service-pilot/config?tabId=settings')} className={'font-normal rounded-md py-[10px]'} name={"Connect Email Account"} />
						</div>
					</div>
				}
			</main>
		</div>
	)
}

export default EmailCoPilot;
