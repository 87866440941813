import React, { useEffect, useState } from 'react';
import { CardDashboard, UserCardDashboard } from '../components/Cards';
import { useStore } from '../store/AppContext';
import { useNavigate } from 'react-router-dom';
import { useDashboard } from "../hooks/useDashboard";
import Table from "../components/Table";
import moment from "moment";
import { TextButton } from '../components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import VideoPlayModal from "../components/VideoPlayModal";
import { ROUTES_ID, USER_ROLE } from '../config';

const DashboardPage = () => {
	const navigate = useNavigate();
	const dashboardHook = useDashboard();
	const { state } = useStore();
	const [appUsageList, setAppUsageList] = useState([]);
	const [documentSearchQueries, setDocumentSearchQueries] = useState([]);
	const [databaseSearchQueries, setDatabaseSearchQueries] = useState([]);
	const [openVideoPlayer, setOpenVideoPlayer] = useState(false)
	const [fileUploadList, setFileUploadList] = useState([]);

	useEffect(() => {
		dashboardHook.getDashboardSummary();
		dashboardHook.getQueries();
	}, [])

	useEffect(() => {
		if (state.dashboardSummary && Object.keys(state.dashboardSummary).length > 0) {
			setAppUsageList([
				{ label: "Service Pilot", value: state.dashboardSummary.coPilotCount },
				// { label: "Database Search", value: state.dashboardSummary.database_search },
				{ label: "Document search", value: state.dashboardSummary.documentSearchCount }
			])
			setFileUploadList([
				{ label: "Service Pilot", value: state.dashboardSummary.docs_count[ROUTES_ID.SERVICE_PILOT] ? state.dashboardSummary.docs_count[ROUTES_ID.SERVICE_PILOT] : 0 },
				{ label: "Database Search", value: state.dashboardSummary.docs_count[ROUTES_ID.DATABASE_SEARCH] ? state.dashboardSummary.docs_count[ROUTES_ID.DATABASE_SEARCH] : 0 },
				{ label: "Document search", value: state.dashboardSummary.docs_count[ROUTES_ID.DOCUMENT_SEARCH] ? state.dashboardSummary.docs_count[ROUTES_ID.DOCUMENT_SEARCH] : 0 }
			])
		}
	}, [state.dashboardSummary])

	useEffect(() => {
		if (state.dashboardQueries.documentSearch && state.dashboardQueries.documentSearch.queries) {
			let documentSearchQueries = [];
			state.dashboardQueries.documentSearch.queries.forEach(documentSearch => {
				documentSearchQueries.push({ created: moment(documentSearch.created).format('DD/MM/YYYY, h:mm a'), query: documentSearch.question, user: ((documentSearch.first_name ? documentSearch.first_name : "") + " " + (documentSearch.last_name ? documentSearch.last_name : "")).trim(), safe_search: documentSearch.safe_search ? JSON.parse(documentSearch.safe_search).label : null })
			})
			setDocumentSearchQueries(documentSearchQueries);
		}

		if (state.dashboardQueries.dbSearch && state.dashboardQueries.dbSearch.queries) {
			let databaseSearchQueries = [];
			state.dashboardQueries.dbSearch.queries.forEach(dbSearch => {
				databaseSearchQueries.push({ created: moment(dbSearch.created).format('DD/MM/YYYY, h:mm a'), query: dbSearch.search_queary, user: dbSearch.first_name + " " + dbSearch.last_name })
			})
			setDatabaseSearchQueries(databaseSearchQueries);
		}
	}, [state.dashboardQueries])

	const onClickMore = (db, page) => {
		dashboardHook.getQueriesPage(db, page);
	}

	const doc_columns = [
		{
			id: "created",
			title: "Date",
			flex: 0.15,
			dataClassName: 'py-3'
		},
		{
			id: "query",
			title: "Query",
			flex: 0.75
		},
		{
			id: "user",
			title: "User",
			flex: 0.1,
		}
	]

	const db_columns = [
		{
			id: "created",
			title: "Date",
			flex: 0.15,
			dataClassName: 'py-3'
		},
		{
			id: "query",
			title: "Query",
			flex: 0.75
		},
		{
			id: "user",
			title: "User",
			flex: 0.1,
		}
	]

	const tooltipInfo = {
		"APP USAGE": "The total number of queried prompts.",
		"FILE UPLOADS": "The total number of uploaded files.",
		"VIDEO TUTORIAL": "Generate AIPC video tutorial on core features and functionalities."
	}

	return (
		<div className="flex px-6">
			<main className="w-full mx-auto">
				{[USER_ROLE.SUPER_ADMIN].includes(state.userRole) ?
					<>
						{state.dashboardSummary && Object.keys(state.dashboardSummary).length > 0 &&
							<div className='grid grid-cols-3 pb-6 gap-6'>
								<CardDashboard title={"APP USAGE"} dataList={appUsageList} tooltipInfo={tooltipInfo} />
								<CardDashboard title={"FILE UPLOADS"} dataList={fileUploadList} tooltipInfo={tooltipInfo} />
								<CardDashboard title={"VIDEO TUTORIAL"} videoLink={true} openVideoPlayer={openVideoPlayer} setOpenVideoPlayer={setOpenVideoPlayer} tooltipInfo={tooltipInfo} />
							</div>
						}
						{documentSearchQueries && documentSearchQueries.length > 0 &&
							<>
								<h4 className='text-gray-dark dark:text-white-600 text-lg font-bold mb-3'>Document Search Queries</h4>
								<div className='relative'>
									<Table columns={doc_columns} data={documentSearchQueries} className={'dashboard-queries-content-container'} />
									{state.dashboardQueries.documentSearch.hasMore &&
										<TextButton addHandleFunction={() => onClickMore('documentSearch', state.dashboardQueries.documentSearch.page + 1)} label={"More"} className={"absolute right-0 text-gray-dark-500 dark:text-white mt-2"} icon={<FontAwesomeIcon icon={faList} />} />
									}
								</div>
							</>
						}
						{databaseSearchQueries && databaseSearchQueries.length > 0 &&
							<>
								<h4 className='text-gray-dark dark:text-white-600 text-lg font-bold mb-3 mt-6'>Database Search Queries</h4>
								<div className='relative'>
									<Table columns={db_columns} data={databaseSearchQueries} className={'dashboard-queries-content-container'} />
									{state.dashboardQueries.dbSearch.hasMore &&
										<TextButton addHandleFunction={() => onClickMore('dbSearch', state.dashboardQueries.dbSearch.page + 1)} label={"More"} className={"absolute right-0 text-gray-dark-500 dark:text-white mt-2"} icon={<FontAwesomeIcon icon={faList} />} />
									}
								</div>
							</>
						}
					</>
					:
					(state.user && state.user.allowed_applications ?
						<div className='grid grid-cols-2 gap-6'>
							{state.user.allowed_applications.includes(ROUTES_ID.SERVICE_PILOT) &&
								<UserCardDashboard icon={'/assets/images/icon-large-co-pilot-selected.svg'} title={'Service Pilot'} subtitle={'Email assistant that instantly summarizes and provides suggested responses.'} onClick={() => navigate('/service-pilot/app')} />
							}
							{state.user.allowed_applications.includes(ROUTES_ID.DATABASE_SEARCH) &&
								<UserCardDashboard icon={'/assets/images/icon-large-db-search-selected.svg'} title={'Database Search'} subtitle={'Search data, build graphs, and return query/code blocks form your data tables.'} onClick={() => navigate('/db-search/app')} />
							}
							{state.user.allowed_applications.includes(ROUTES_ID.DOCUMENT_SEARCH) &&
								<UserCardDashboard icon={'/assets/images/icon-large-doc-search-selected.svg'} title={'Document Search'} subtitle={'Summarize, view, and ask questions from your document repository.'} onClick={() => navigate('/doc-search/app')} />
							}
						</div>
						:
						<div className="w-full flex justify-center">
							<p className="text-gray-dark dark:text-white text-lg font-semibold, mt-12">You don't have permissions for the applications. Please contact your admin.</p>
						</div>
					)
				}
				<VideoPlayModal isOpen={openVideoPlayer} data={[]} onClose={() => setOpenVideoPlayer(false)} />
			</main>
		</div>
	)
}

export default DashboardPage;
