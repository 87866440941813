import React, { useEffect, useState } from 'react';
import { InputWrapper } from './InputWrapper';
import DropdownWrapper from './DropdownWrapper';
import FileUpload from './DocSearch/FileUpload';
import { ACCEPTED_FILE_TYPE, DATABASE_TYPES, DATABASE_TYPES_DICT, ROUTES_ID } from '../config';
import { Button } from './Button';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DBConfigWrapper = ({ title, index, dbConfig, setDBConfig, onSave }) => {
    const [databaseType, setDatabaseType] = useState();
    const [config, setConfig] = useState([]);
    const [databaseName, setDatabaseName] = useState('')
    const [enableSaveButton, setEnableSaveButton] = useState(true)
    const [viewPassword, setViewPassword] = useState('');

    useEffect(() => {
        if (config && Object.keys(config).length > 0) {
            let prevDBConfig = dbConfig.database ? dbConfig.database : [];
            prevDBConfig[index] = config;
            setDBConfig((prevData) => ({ ...prevData, database: prevDBConfig }));
            setEnableSaveButton(config.databaseName && config.host && config.port && config.dbUsername && config.password)
        }
    }, [config])

    useEffect(() => {
        databaseType && setConfig((prevData) => ({ ...prevData, databaseType: databaseType }));
    }, [databaseType])

    const onChange = (e) => {
        setConfig((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
    }

    const onDatabaseNameChanged = (value) =>{
        setDatabaseName(value)
        setConfig((prevData) => ({ ...prevData, databaseName: value }));
    }

    return (
        <div className='mb-2'>
            <h5 className="text-gray-dark dark:text-white font-bold">{title}</h5>
            <form onChange={onChange}>
                <DropdownWrapper placeholder="Select a database" options={DATABASE_TYPES} value={databaseType ? databaseType.label : ''} setValue={setDatabaseType} name={'databaseType'} type={"text"} />
                { databaseType && databaseType.label && databaseType.id === 'bigquery' && <>
                    <InputWrapper value={config['projectName'] ? config['projectName'] : ''} label={"Project Name"} name={'projectName'} type={"text"} containerStyle={'mb-4'} />
                    <InputWrapper value={config['serviceAccountFile'] ? config['serviceAccountFile'] : ''} label={"Service Account File"} name={'serviceAccountFile'} type={"text"} containerStyle={'mb-4'} />
                    <InputWrapper value={config['databaseName'] ? config['databaseName'] : ''} label={"Database Name"} name={'databaseName'} type={"text"} containerStyle={'mb-4'} />
                    <p className='text-gray -mt-4 text-sm'>For more information on retrieving your Big Query Database details, please visit the following <a href='https://cloud.google.com/bigquery/docs/tables-intro' target="_blank" className='text-blue underline'>link</a>.</p>
                </> }
                { databaseType && databaseType.label && (databaseType.id === DATABASE_TYPES_DICT.csv || databaseType.id === DATABASE_TYPES_DICT.excel) && <>
                    <InputWrapper setValue={(value) => onDatabaseNameChanged(value)}  value={databaseName} label={"Database Name"} name={'databaseName'} type={"text"} containerStyle={'mb-4'} />
                    <FileUpload disabled={databaseName === ""} appId={ROUTES_ID.DATABASE_SEARCH} name={'Upload a file'} acceptFiles={ACCEPTED_FILE_TYPE[databaseType.id]} allowMultiple={false} folder={false} path={'db_search'} dbConfig={dbConfig} setDBConfig={setDBConfig}/>
                </> }
                { databaseType && databaseType.label && databaseType.id === DATABASE_TYPES_DICT.mysql &&  <>
                    <InputWrapper value={config['databaseName'] ? config['databaseName'] : ''} label={"Database name"} name={'databaseName'} type={"text"} containerStyle={'mb-4'} />    
                    <InputWrapper value={config['host'] ? config['host'] : ''} label={"Database Host"} name={'host'} type={"text"} containerStyle={'mb-4'} />
                    <InputWrapper value={config['port'] ? config['port'] : ''} label={"Database Port"} name={'port'} type={"text"} containerStyle={'mb-4'} />
                    <InputWrapper value={config['dbUsername'] ? config['dbUsername'] : ''} label={"Username"} name={'dbUsername'} type={"text"} containerStyle={'mb-4'} />
                    <InputWrapper value={config['password'] ? config['password'] : ''} label={"Password"} name={"password"} type={viewPassword ? "text" : "password"} rightIcon={config['password'] && <FontAwesomeIcon icon={viewPassword ? faEye : faEyeSlash} onClick={() => setViewPassword(!viewPassword)} className="text-gray-dark dark:text-white cursor-pointer" />} containerStyle={'mb-4'} autoComplete={'new-password'} />
                    <p className='text-gray -mt-4 text-sm mb-4'>For more information on retrieving your MySql Database details, please visit the following <a href='https://dev.mysql.com/doc/refman/8.0/en/database-use.html' target="_blank" className='text-blue underline'>link</a>.</p>
                    <Button disabled={!enableSaveButton} name={"Save"} handleFunction={onSave} type={"fill"} className={'w-full py-[8px]'} />
                </> }
            </form>
        </div>
    )
}

export default DBConfigWrapper;
