import React, { useState } from 'react';
import BottomBar from './Home/BottomBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const ProgressSteperWrapper = ({ stepComponents, onSave }) => {
    const [currentStep, setCurrentStep] = useState(0);

    const handleSave = () => {
        setCurrentStep(stepComponents.length);
        onSave && onSave();
    }

    const handleNext = () => {
        (currentStep < stepComponents.length) && setCurrentStep(step => step + 1);
    }

    const ButtonList = [
        {
            "name": (currentStep >= stepComponents.length - 1) ? "Save" : "Next",
            "handleFunction": (currentStep >= stepComponents.length - 1) ? handleSave : handleNext,
            "disabled": false
        }
    ]

    return (
        <div className="flex-col w-full items-center">
            <div className="flex-col w-full items-center">
                <div className="flex w-full items-center pr-6 pb-6">
                    <div className="w-full">
                        <div className="flex flex-row justify-center">
                            {stepComponents && (stepComponents.length > 1) && stepComponents.map((element, index) => (
                                <div key={index} onClick={() => setCurrentStep(index)} className='flex flex-row items-center justify-center cursor-pointer'>
                                    {(index < currentStep) &&
                                        <div className='flex justify-center items-center w-7 h-7 border-2 rounded-full p-2 border-blue bg-blue'>
                                            <FontAwesomeIcon className="w-5 text-white-gray dark:text-gray-black" icon={faCheck} />
                                        </div>
                                    }
                                    {(index === currentStep) &&
                                        <div className='flex justify-center items-center w-7 h-7 border-2 rounded-full p-2 border-blue'>
                                            <p className="text-blue text-sm font-medium">{index + 1}</p>
                                        </div>
                                    }
                                    {(index > currentStep) &&
                                        <div className='flex justify-center items-center w-7 h-7 border-2 rounded-full p-2 border-gray-700 dark:border-gray-medium'>
                                            <p className="text-gray-700 dark:text-gray-medium text-sm font-medium">{index + 1}</p>
                                        </div>
                                    }
                                    {index < (stepComponents.length - 1) &&
                                        <div className={`w-10 h-0 border ${(index < currentStep) ? 'border-blue' : 'border-gray-700 dark:border-gray-medium'}`}>
                                        </div>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={`px-6 relative overflow-auto hide-scrollbar ${onSave ? 'progress-steper-content-container' : 'progress-steper-content-container-without-bottom-bar'}`}>
                    {stepComponents[currentStep > (stepComponents.length - 1) ? (stepComponents.length - 1) : currentStep]}
                </div>
            </div>
            {onSave &&
                <BottomBar ButtonList={ButtonList} />
            }
        </div>
    )
}

export default ProgressSteperWrapper;
