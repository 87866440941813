import { faFile, faFileCsv, faFileExcel, faFilePdf, faFilePowerpoint, faFileText, faFileWord} from '@fortawesome/free-solid-svg-icons'

export const scrollToBottom = (chatContainerRef) => {
    if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
};

export const getFileTypeIcon = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
    case 'pdf':
        return faFilePdf;
    case 'csv':
        return faFileCsv;
    case 'xls':
        return faFileExcel;
    case 'xlsx':
        return faFileExcel;
    case 'doc':
        return faFileWord;
    case 'docx':
        return faFileWord;
    case 'ppt':
        return faFilePowerpoint;
    case 'pptx':
        return faFilePowerpoint;
    case 'txt':
        return faFileText;
    default:
        return faFile; 
    }
};
