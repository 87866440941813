import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useStore } from '../../store/AppContext';
import { NavigationLink } from './navComponents';
import { useConfig } from '../../hooks/useConfig';
import packageJson from '../../../package.json';
import { useUser } from "../../hooks/useUser";
import { InputWrapper2 } from '../InputWrapper';
import { Button } from '../Button';
import axios from 'axios';
import Cookies from 'js-cookie';
import { ROUTES_ID, SUB_PAGE_APPS, TOOL_TIPS, USER_ROLE } from '../../config';

const UserNavigation = [
	{
		"name": "Dashboard",
		"id": ROUTES_ID.DASHBOARD,
		"location": "/dashboard",
		"locationId": "dashboard",
		"icon": "/assets/images/icon-nav-dashboard.svg",
		"lightModeIcon": "/assets/images/icon-nav-dashboard-light.svg",
		"selectedIcon": "/assets/images/icon-nav-dashboard-selected.svg",
		"isApplication": false,
		"toolTip": TOOL_TIPS[ROUTES_ID.DASHBOARD],
		"toolTipPosition": "bottom"
	},
	{
		"name": "Service Pilot",
		"id": ROUTES_ID.SERVICE_PILOT,
		"location": "/service-pilot/app",
		"locationId": "service-pilot",
		"icon": "/assets/images/icon-nav-co-pilot.svg",
		"lightModeIcon": "/assets/images/icon-nav-co-pilot-light.svg",
		"selectedIcon": "/assets/images/icon-nav-co-pilot-selected.svg",
		"isApplication": true,
		"toolTip": TOOL_TIPS[ROUTES_ID.SERVICE_PILOT],
		"toolTipPosition": "bottom"
	},
	{
		"name": "Database Search",
		"id": ROUTES_ID.DATABASE_SEARCH,
		"location": "/db-search/app",
		"locationId": "db-search",
		"icon": "/assets/images/icon-nav-db-search.svg",
		"lightModeIcon": "/assets/images/icon-nav-db-search-light.svg",
		"selectedIcon": "/assets/images/icon-nav-db-search-selected.svg",
		"isApplication": true,
		"toolTip": TOOL_TIPS[ROUTES_ID.DATABASE_SEARCH],
		"toolTipPosition": "bottom"
	},
	{
		"name": "Document Search",
		"id": ROUTES_ID.DOCUMENT_SEARCH,
		"location": "/doc-search/app",
		"locationId": "doc-search",
		"icon": "/assets/images/icon-nav-doc-search.svg",
		"lightModeIcon": "/assets/images/icon-nav-doc-search-light.svg",
		"selectedIcon": "/assets/images/icon-nav-doc-search-selected.svg",
		"isApplication": true,
		"toolTip": TOOL_TIPS[ROUTES_ID.DOCUMENT_SEARCH],
		"toolTipPosition": "bottom"
	},
	null,
	{
		"name": "Settings",
		"id": ROUTES_ID.SETTINGS,
		"location": "/config?tabId=settings",
		"locationId": "settings",
		"icon": "/assets/images/settings-nav.svg",
		"lightModeIcon": "/assets/images/settings-nav-light.svg",
		"selectedIcon": "/assets/images/settings-nav-selected.svg",
		"isApplication": true,
		"toolTip": TOOL_TIPS[ROUTES_ID.SETTINGS],
		"toolTipPosition": "bottom"
	},
	{
		"name": "Integrations",
		"id": ROUTES_ID.INTEGRATIONS,
		"location": "/config?tabId=integrations",
		"locationId": "integrations",
		"icon": "/assets/images/integrations-nav.svg",
		"lightModeIcon": "/assets/images/integrations-nav-light.svg",
		"selectedIcon": "/assets/images/integrations-nav-selected.svg",
		"isApplication": true,
		"toolTip": TOOL_TIPS[ROUTES_ID.INTEGRATIONS],
		"toolTipPosition": "bottom"
	}
]

const AdminNavigation = [
	...UserNavigation,
	null,
	{
		"name": "Authentication",
		"id": ROUTES_ID.AUTHENTICATION,
		"location": "/auth",
		"locationId": "auth",
		"icon": "/assets/images/icon-nav-authentication.svg",
		"lightModeIcon": "/assets/images/icon-nav-authentication-light.svg",
		"selectedIcon": "/assets/images/icon-nav-authentication-selected.svg",
		"isApplication": false,
		"toolTip": TOOL_TIPS[ROUTES_ID.AUTHENTICATION],
		"toolTipPosition": "top"
	},
	{
		"name": "User Access",
		"id": ROUTES_ID.USER_ACCESS,
		"location": "/users",
		"locationId": "users",
		"icon": "/assets/images/icon-nav-user-access.svg",
		"lightModeIcon": "/assets/images/icon-nav-user-access-light.svg",
		"selectedIcon": "/assets/images/icon-nav-user-access-selected.svg",
		"isApplication": false,
		"toolTip": TOOL_TIPS[ROUTES_ID.USER_ACCESS],
		"toolTipPosition": "top"
	},
]

const NAVIGATIONS = {
	[USER_ROLE.SUPER_ADMIN]: AdminNavigation,
	[USER_ROLE.ADMIN]: AdminNavigation,
	[USER_ROLE.USER]: UserNavigation
}

const Sidebar = ({ isOpen, setSectionTitle, setActiveTabId, sectionTitle, setActiveVDB, setActiveLLM, activeTabId }) => {
	const location = useLocation();
	const configHook = useConfig();
	const usersHook = useUser();
	const feedbackPopupRef = useRef(null);
	const { state, dispatch } = useStore();
	const [openFeedbackPopup, setOpenFeedBackPopup] = useState(false);
	const [feedback, setFeedback] = useState('');
	const [feedbackSubmit, setFeedbackSubmit] = useState(false);
	const [navList, setNavList] = useState(AdminNavigation);

	useEffect(() => {
		configHook.getConfig('ALL');
		usersHook.getUserConfig();
	}, [state.toneOfVoiceConfig, state.coPilotConfig, state.docSearchConfig, state.dbSearchConfig])

	useEffect(() => {
		setNavList(NAVIGATIONS[state.userRole] ? NAVIGATIONS[state.userRole] : null);
	}, [state.userRole])

	useEffect(() => {
		const selectedNav = navList.find(navigationObj => navigationObj && (navigationObj.locationId === location.pathname.split('/')[1]));
		if (selectedNav) {
			setSectionTitle((selectedNav && selectedNav.name) || "Dashboard");
			setActiveTabId((selectedNav && selectedNav.id) || ROUTES_ID.DASHBOARD)

			if (selectedNav.isApplication) {
				const activeLlm = state.userConfig && state.userConfig.llm_config ? state.userConfig.llm_config[selectedNav.id] : {}
				setActiveLLM(activeLlm ? activeLlm.label : null)

				const activeVdb = state.userConfig && state.userConfig.vdb_config ? state.userConfig.vdb_config[selectedNav.id] : {};
				setActiveVDB(activeVdb && activeVdb.label ? activeVdb.label : 'Chroma DB')
			} else {
				setActiveLLM(null);
				setActiveVDB(null);
			}
		}
	}, [location, state.allConfig, state.userConfig])

	useEffect(() => {
		const handleClickOutsideLLM = (event) => {
			if (feedbackPopupRef.current && !feedbackPopupRef.current.contains(event.target)) {
				setOpenFeedBackPopup(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutsideLLM);
		return () => document.removeEventListener("mousedown", handleClickOutsideLLM);
	}, [feedbackPopupRef])

	useEffect(() => {
		const popupDiv = document.getElementById('feedback_popup_div');
		if (openFeedbackPopup) {
			popupDiv.style.display = 'flex';
		} else {
			setTimeout(() => {
				popupDiv.style.display = 'none';
			}, 500);
		}
	}, [openFeedbackPopup])

	useEffect(() => {
		setFeedback('');
	}, [openFeedbackPopup])

	useEffect(() => {
		feedbackSubmit && setTimeout(() => {
			setOpenFeedBackPopup(false);
			setTimeout(() => {
				setFeedbackSubmit(false);
			}, 1000);
		}, 3000);
	}, [feedbackSubmit])

	const onFeedBackChange = (e) => {
		setFeedback(e.target.value);
	}

	const onSubmit = async () => {
		let geoLocation = null;
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					geoLocation = {
						latitude: position.coords.latitude,
						longitude: position.coords.longitude,
					};
				},
				(error) => {
					console.log({ error: error.message });
				}
			);
		} else {
			console.log({ error: 'Geolocation is not supported by this browser.' });
		}

		let ip = null;

		await fetch('https://api.ipify.org?format=json')
			.then(response => response.json())
			.then(data => ip = data.ip)
			.catch(error => console.log('Error fetching IP address:', error));

		let user = JSON.parse(Cookies.get('user'));
		const data = {
			app: 'Generate Enterprise',
			hosturl: window.origin,
			ip: ip,
			geoLocation: geoLocation,
			username: user.username,
			feedback: feedback,
			created: new Date().toISOString()
		}

		console.log(data)
		dispatch({
			type: "LOADING",
			payload: true
		})
		axios.post('https://generateadmin.interplay.iterate.ai/feedback', data)
			.then(() => {
				dispatch({
					type: "LOADING",
					payload: false
				})
				setFeedbackSubmit(true);
			})
			.catch(() => {
				dispatch({
					type: "LOADING",
					payload: false
				})
				dispatch({
					type: "SET_MESSAGE",
					payload: {
						type: "fail",
						title: "Save feedbak failed",
						subtitle: "Unable to save your feedback now,Please try again!"
					}
				})
			})
	}

	const getItemLocation = (item) => {
		if ((activeTabId === ROUTES_ID.SERVICE_PILOT) && [ROUTES_ID.SETTINGS, ROUTES_ID.INTEGRATIONS].includes(item.id))
			return `/service-pilot/config?tabId=${item.locationId}`
		else if ((activeTabId === ROUTES_ID.DATABASE_SEARCH) && [ROUTES_ID.SETTINGS, ROUTES_ID.INTEGRATIONS].includes(item.id))
			return `/db-search/config?tabId=${item.locationId}`
		else if ((activeTabId === ROUTES_ID.DOCUMENT_SEARCH) && [ROUTES_ID.SETTINGS, ROUTES_ID.INTEGRATIONS].includes(item.id))
			return `/doc-search/config?tabId=${item.locationId}`
		 
		return item.location;
	}

	const onClose = () => {
		setOpenFeedBackPopup(false);
		setTimeout(() => {
			setFeedbackSubmit(false);
		}, 1000);
	}

	return (
		<div className={`fixed h-screen z-40 bg-white dark:bg-gray-dark border-white-gray-dark-500 dark:border-gray-medium text-gray-dark dark:text-white py-4 transform ${isOpen ? 'w-80 translate-x-0 border-r' : '-translate-x-full border-r-0 w-0'} transition-transform ease-in-out duration-300`}>
			<div className={`${isOpen ? 'block' : 'hidden'}`}>
				<div className={`w-full text-black dark:text-white overflow-hidden py-4`}>
					<div className='flex flex-col items-center justify-center px-6'>
						<img src={state.isDarkMode ? "/assets/images/logo-generate-enterprise-dark-mode.svg" : "/assets/images/logo-generate-enterprise-light-mode.svg"} alt="interplay-logo" className='w-60' />
						<p className='w-60 text-end text-xs font-semibold'>v{packageJson.version}</p>
					</div>
				</div>
				<div className="side-bar-content-container pt-1 overflow-auto">
					<nav className='px-4'>
						{navList.map((item, key) => (
							item ?
								(((state.user && state.user.allowed_applications && state.user.allowed_applications.includes(item.id)) || (state.user && state.user.allowed_applications && (state.user.allowed_applications.length > 0) && ['SETTINGS', 'INTEGRATIONS'].includes(item.id)) || [USER_ROLE.SUPER_ADMIN].includes(state.userRole) || (item.id === ROUTES_ID.DASHBOARD)) &&
									<NavigationLink disabled={SUB_PAGE_APPS[item.id] && SUB_PAGE_APPS[item.id][state.userRole] && !SUB_PAGE_APPS[item.id][state.userRole].includes(activeTabId)} setSection={setSectionTitle} link={getItemLocation(item)} title={item.name} icon={item.icon} lightModeIcon={item.lightModeIcon} selectedIcon={item.selectedIcon} key={key} sectionTitle={sectionTitle} toolTip={item.toolTip} toolTipPosition={item.toolTipPosition} />
								)
								:
								<div key={key} className='w-11/12 border-t-2 mx-auto my-2 border-white-gray-dark-500 dark:border-gray-medium' />
						))}
					</nav>
				</div>
				<div className='fixed bottom-0 flex w-full justify-center items-center'>
					<button disabled={!navigator.onLine} onClick={() => setOpenFeedBackPopup(true)} className='px-3 py-1 rounded-tl-[6px] rounded-tr-[6px] text-gray-dark dark:text-white disabled:text-gray disabled:dark:text-white-gray bg-white-gray-700 dark:bg-gray-black hover:bg-blue hover:dark:bg-blue disabled:bg-gray-light-700 disabled:dark:bg-gray disabled:hover:bg-gray-light-700 disabled:hover:dark:bg-gray hover:text-white hover:dark:text-gray-dark'>
						<p className='text-xs font-normal'>Feedback</p>
					</button>
				</div>
				<div ref={feedbackPopupRef} id={'feedback_popup_div'} className='fixed px-6 bottom-0 w-full z-[100] hidden items-center justify-center'>
					<div className={`${openFeedbackPopup ? 'popup-ease-in ' : 'popup-ease-out '} p-4 rounded-tl-[8px] rounded-tr-[8px] bg-white-gray-700 dark:bg-gray-black z-10`}>
						{feedbackSubmit ?
							<>
								<p className='mb-[10px] text-gray-dark dark:text-white text-base font-semibold'>Feedback sent, thank you!</p>
								<p className='mb-6 pr-2 text-gray-dark dark:text-white text-xs font-normal'>We use feedback like yours to help improve Generate.</p>
								<Button handleFunction={onClose} className={'w-full text-xs font-normal rounded-md py-[10px]'} name={"Close"} />
							</>
							:
							<>
								<p className='mb-[10px] text-gray-dark dark:text-white text-base font-semibold'>Help us improve Generate</p>
								<form onChange={onFeedBackChange}>
									<InputWrapper2 value={feedback} name={"instructions"} type={"textarea"} placeholder={`Tell us how we can improve the experience....`} rows={5} textClassName={'text-gray-dark-700 dark:text-white-gray-400 text-sm font-normal'} />
								</form>
								<div className='flex flex-row items-center mb-[10px] py-2'>
									<p className='pr-2 text-gray-dark-700 dark:text-white-gray-400 text-xs font-normal'>Please don’t include any sensitive information</p>
									<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M10.875 6.5C10.875 4.76562 9.9375 3.17188 8.4375 2.28125C6.91406 1.41406 5.0625 1.41406 3.5625 2.28125C2.03906 3.17188 1.125 4.76562 1.125 6.5C1.125 8.25781 2.03906 9.85156 3.5625 10.7422C5.0625 11.6094 6.91406 11.6094 8.4375 10.7422C9.9375 9.85156 10.875 8.25781 10.875 6.5ZM0 6.5C0 4.36719 1.125 2.39844 3 1.32031C4.85156 0.242188 7.125 0.242188 9 1.32031C10.8516 2.39844 12 4.36719 12 6.5C12 8.65625 10.8516 10.625 9 11.7031C7.125 12.7812 4.85156 12.7812 3 11.7031C1.125 10.625 0 8.65625 0 6.5ZM3.96094 4.39062C4.14844 3.85156 4.64062 3.5 5.20312 3.5H6.5625C7.38281 3.5 8.0625 4.17969 8.0625 5C8.0625 5.51562 7.75781 6.00781 7.3125 6.26562L6.5625 6.71094C6.53906 7.01562 6.30469 7.25 6 7.25C5.67188 7.25 5.4375 7.01562 5.4375 6.6875V6.38281C5.4375 6.17188 5.53125 5.98438 5.71875 5.89062L6.75 5.30469C6.86719 5.23438 6.9375 5.11719 6.9375 5C6.9375 4.78906 6.77344 4.64844 6.5625 4.64844H5.20312C5.13281 4.64844 5.0625 4.69531 5.03906 4.76562L5.01562 4.78906C4.92188 5.09375 4.59375 5.23438 4.3125 5.14062C4.00781 5.02344 3.86719 4.69531 3.96094 4.41406V4.39062ZM5.25 8.75C5.25 8.35156 5.57812 8 6 8C6.39844 8 6.75 8.35156 6.75 8.75C6.75 9.17188 6.39844 9.5 6 9.5C5.57812 9.5 5.25 9.17188 5.25 8.75Z" fill="#6E747B" />
									</svg>
								</div>
								<div className='flex flex-row gap-3 justify-center items-center mt-6'>
									<Button handleFunction={() => setOpenFeedBackPopup(false)} type={'outline'} className={'w-full text-xs font-normal rounded-md py-[10px]'} name={"Cancel"} />
									<Button disabled={!feedback} handleFunction={onSubmit} className={'w-full text-xs font-normal rounded-md py-[10px]'} name={"Submit"} />
								</div>
							</>
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Sidebar;
